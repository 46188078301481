import Value from '../Value.js';
import { linear as interpolation }  from 'everpolate'; // ЛИНЕЙНАЯ ИНТЕРПОЛЯЦИЯ
import { default as NewtonMethod } from '../newtonMethod.js'
import { default as temperatureValidation } from './temperatureValidation.js'
const EPS_BALANCE = 1e-2;

export default class Balance extends temperatureValidation {
  constructor(N, coldSide, hotSide) {
    super(coldSide, hotSide);
    ////////////////////////////
    this.N = N || new Value('Гкал/ч');
    ////////////////////////////
    this.setConditionValues('hotSide', {
      Q_m: hotSide.Q_m || new Value('т/ч'),
    });
    ////////////////////////////
    this.setConditionValues('coldSide', {
      Q_m: coldSide.Q_m || new Value('т/ч'),
    });
    ////////////////////////////
    this.isUseTemperatureBalance = true; // АВТОМАТИЧЕСКИ СВОДИТЬ ДАННЫЕ ПО ТЕПЛОВОМУ БАЛАНСУ
    ////////////////////////////
  }
  setN(value) {
    this.N = value;
    if (this.isUseTemperatureBalance) this._onChangeN();
    return this;
  }
  setConditionValues(side, props) {
    for (let key in props) {
      this[side].props[key] = props[key];
      if (this.isUseTemperatureBalance && this[`_onChange${key}`]) this[`_onChange${key}`](side);
    }
    return this;
  }
  _tryToConstructParameters() {
    for (const side of ['coldSide', 'hotSide']) {
      if (this[side].props['T_input'].value == 0 || this[side].props['T_output'].value == 0) return false;
    }
    if (this.N.value != 0 && this.hotSide.props.Q_m.value == 0 && this.coldSide.props.Q_m.value == 0) {
      console.log('1st scenario autofill balance');
      this._balanceQ_m('hotSide');
      this._balanceQ_m('coldSide');
      return true;
    }
    if (this.N.value == 0 && !(this.hotSide.props.Q_m.value != 0 && this.coldSide.props.Q_m.value != 0)) {
      console.log('2nd scenario autofill balance');
      if (this.hotSide.props.Q_m.value != 0) {
        this._balanceN('hotSide');
        this._balanceQ_m('coldSide');
      } else {
        this._balanceN('coldSide');
        this._balanceQ_m('hotSide');
      }
      return true;
    }
    return false;
  }
  checkBalance() {
    let result = true,
      eps = EPS_BALANCE;
    if (!this._checkHaveAllParametrs() && !this._tryToConstructParameters())
      return {
        success: false,
        error: { message: 'Не все значения заполнены, автобаланс не был произведён', code: 0 },
      };
    const ctv = this._checkTemperatureValues();
    if (!ctv.success) return ctv;

    for (let side of ['hotSide', 'coldSide']) {
      let Q_m = this[side].props.Q_m.toCI();
      let C = this._interpolate_prop_of_condition(side, 'specific_heat', this._averange_temperature(side));
      let N_fake = Q_m * C * Math.abs(this[side].props.T_input.toCI() - this[side].props.T_output.toCI());
      // MAPE (see https://www.wikiwand.com/en/Mean_absolute_percentage_error)
      result = result && Math.abs(N_fake - this.N.toCI()) / this.N.toCI() < eps;
    }
    if (result) return { success: true };
    else
      return {
        success: false,
        error: { message: 'Тепловой баланс нарушен', code: 1 },
      };
  }
  getDataToServer() {
    return {
      N: this.N,
      coldSide: {
        props: this.coldSide.props,
        conditionName: this.coldSide.condition.name,
      },
      hotSide: {
        props: this.hotSide.props,
        conditionName: this.hotSide.condition.name,
      },
    };
  }
  _interpolate_prop_of_condition(side, prop, point) {
    const [tmp] = interpolation(
      point,
      this[side].condition[prop].map((el) => el.t),
      this[side].condition[prop].map((el) => el.value)
    );
    return tmp;
  }
  _checkHaveAllParametrs() {
    let result = super._checkHaveAllParametrs();
    result = result && this.N.value;
    return result;
  }
  _onChangeN() {
    for (let side of ['hotSide', 'coldSide'])
      if (this[side].props.T_input.value && this[side].props.T_output.value) this._balanceQ_m(side);
  }
  _onChangeCondition(side) {
    if (this[side].props.T_output.value && this.N.value) this._balanceQ_m(side);
  }
  _averange_temperature(side) {
    return (this[side].props.T_output.toCI() + this[side].props.T_input.toCI()) / 2;
  }
  _balanceQ_m(side) {
    let sign = 1;
    if (side === 'coldSide') sign = -1;
    const C = this._interpolate_prop_of_condition(side, 'specific_heat', this._averange_temperature(side));
    this[side].props.Q_m = new Value(
      this.N.toCI() / (C * sign * (this[side].props.T_input.toCI() - this[side].props.T_output.toCI())),
      'кг/с'
    )
      .convertFromCI(this[side].props.Q_m.dimension)
      .round(3);
  }
  _onChangeQ_m(side) {
    if (this[side].props.T_input.value && this.N.value) this._balanceT_output(side);
  }
  _balanceT_output(side) {
    const C_dT = this.N.toCI() / this[side].props.Q_m.toCI();
    const T2 = NewtonMethod((X) => {
      return (
        this._interpolate_prop_of_condition(side, 'specific_heat', (X + this[side].props.T_input.toCI()) / 2) *
          Math.abs(X - this[side].props.T_input.toCI()) -
        C_dT
      );
    }, this[side].props.T_output.toCI());
    this[side].props.T_output = new Value(T2, 'град. Цельсия')
      .convertFromCI(this[side].props.T_output.dimension)
      .round(3);
  }
  _balanceN(side) {
    const C = this._interpolate_prop_of_condition(side, 'specific_heat', this._averange_temperature(side));
    let N =
      this[side].props.Q_m.toCI() * C * Math.abs(this[side].props.T_input.toCI() - this[side].props.T_output.toCI());
    this.N = new Value(N, 'вт').convertTo(this.N.dimension).round(4);
  }
  _onChangeT_input(side) {
    if (this[side].props.T_output.value && this.N.value) this._balanceQ_m(side);
  }
  _onChangeT_output(side) {
    if (this[side].props.T_input.value && this.N.value) this._balanceQ_m(side);
  }
}


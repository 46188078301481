export default {
  //prettier-ignore
  'вт': 1, // СИ
  //prettier-ignore
  'кг': 1, // СИ
  //prettier-ignore
  'па': 1, // СИ
  //prettier-ignore
  'кг/с': 1, // СИ
  //prettier-ignore
  'град. Цельсия': 1, // СИ
  //prettier-ignore
  'Гкал/ч': 1163000, // ГИГАкалорий/час -> ватт
  //prettier-ignore
  'ккал/ч': 1.163, // КИЛОкалорий/час -> ватт
  //prettier-ignore
  'Мкал/ч': 1163, // ГИГАкалорий/час -> ватт
  //prettier-ignore
  'кВт': 1000, // КИЛОватт -> ватт
  //prettier-ignore
  'МВт': 1000000, // МЕГА ватт -> ватт
  //prettier-ignore
  'т/ч': 1000/3600, // ТОННЫ/ЧАС -> килограм/секунду
  //prettier-ignore
  'м.вод.ст': 9806.6501248092, // милиметр водяного столба -> паскали
  //prettier-ignore
  'кПа': 1000, // КИЛО паскали -> паскали
  //prettier-ignore
  'бар': 100000 // БАР -> паскали
};

import Value from '../Value.js';
import SteamBalance from './steamBalance.js'

export default class DGVSBalance extends SteamBalance {
  constructor(N, coldSide, hotSide) {
    super(N, coldSide, hotSide)
    this.setConditionValues('hotSide', {
      Q_m2Stage: hotSide.Q_m2Stage || new Value('т/ч'),
    });

    this.CONST = 1.143
  }
  _balanceQ_m(side) {
    super._balanceQ_m(side)
    this._set_Q_m2Stage(this.coldSide.props.Q_m)
  }
  _onChangeQ_m(side) {
    super._onChangeQ_m(side)
    this._set_Q_m2Stage(this.coldSide.props.Q_m)
  }
  _set_Q_m2Stage(Q_m) {
    this.hotSide.props.Q_m2Stage = new Value(
      Q_m.toCI() * this.CONST,
      'кг/с'
    )
      .convertFromCI(this.hotSide.props.Q_m2Stage.dimension)
      .round(3);
  }
  getDataToServer() {
    const data = super.getDataToServer()
    data.hotSide.conditionName = 'Вода';
    return data;
  }
}

// https://api.etrann-online.com
require('dotenv').config();
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_MODE = process.env.REACT_APP_APP_MODE;

export const GLOBAL_STATE = {
  toastOpen: false,
  toastAutohideDuration: 15000,
  toastStyle: 'success',
  toastMessage: '',
};

export const ROUTES_LIST = {
  rptoCalculation: '/rpto-calculation',
  technologyCalculation: '/technology-calculation',
  personalInfo: '/personal-info',
  calculationArchive: '/calculation-archive',
  rptoCalculationPoverochniy: '/rpto-calculation-poverochniy',
  modelsCatalog: '/models-catalog',
  adminPanel: '/admin-panel',
  documents: '/documents',
  catalogs: '/catalogs',
  commercialOffer: '/commercial-offer',
  commercialOfferHistory: '/commercial-offer-history',
  invoicesPaymentsHistory: '/invoices-payments-history',
  hotWaterSupply: '/hot-water-supply',
  hotWaterSupplyCalc: '/hot-water-supply-calc',
  itpCalculation: '/itp-calculation',
};

export const API_ENDPOINTS_LIST = {
  //AUTH
  sign_up: '/auth/singUp',
  login: '/auth/login',
  reset_password: '/auth/resetPassword',
  //USER
  get_user: '/user/info',
  get_consultant: '/user/ik',
  change_password: '/user/changePassword',
  login_change: '/user/changeEmail',
  support_message: '/user/support',
  //ADMIN
  get_users: '/admin/getUsers',
  set_user_status: '/admin/setUserStatus',
  set_user_discount: '/admin/setUserDiscount',
  get_consultants_list: '/admin/consultant/list',
  set_user_consultant: '/admin/consultant/set',
  set_user_clientId: '/admin/changeClientId',
  //PDF
  pdf_view: '/pdf/view',
  pdf_download: '/pdf/download',
  pdfs_store_view: '/pdf/view/store',
  pdfs_store_download: '/pdf/download/store',
  pdf_offer_download: '/pdf/download/offer',
  pdf_get_token: '/pdf/getToken',
  pdfs_get_store_token: '/pdf/getToken/store',
  pdf_get_offer_token: '/pdf/getToken/offer',
  //CALCULATION
  raschet_ovic: '/calculation/ovick',
  raschet_poverochniy: '/calculation/check',
  get_pto: '/calculation/pto',
  raschet_gvs: '/calculation/gvs',
  raschet_dgvs: '/calculation/dgvs',
  raschet_par: '/calculation/steam',
  raschet_block_itp: '/calculation/blockITP',
  //STORE
  set_store: '/store',
  get_store: '/store',
  get_store_object_names_unique: '/store/object-names/unique',
  //3D MODELS
  get_models: '/3dModels',
  //DOCS
  get_docs: '/docs',
  //CART
  get_global_cart_data: '/cart/info',
  get_cart_data: '/cart',
  set_cart_data: '/cart',
  del_cart_data: '/cart',
  update_cart_data: '/cart',
  //OFFERS
  get_offers: '/offers',
  set_offer: '/offers/archive',
  set_temp_cart: '/offers/fill-temp-cart',
};

export const USER_TYPES_LIST = {
  individual: 'individual',
  legalEntities: 'legal',
};

export const REGISTER_OPTIONS_LIST = {
  0: {
    value: USER_TYPES_LIST.individual,
    label: 'Регистрация физического лица',
    style: { margin: '0' },
  },
  1: {
    value: USER_TYPES_LIST.legalEntities,
    label: 'Регистрация юридического лица',
    style: { marginLeft: '30px' },
  },
};

export const COUNTRY_CODES_LIST = {
  Russia: '+7',
  Ukraine: '+380',
  Armenia: '+374',
  Azerbaijan: '+994',
  Belarus: '+375',
  Moldova: '+373',
  Latvia: '+371',
  Lithuania: '+370',
  Estonia: '+372',
  Georgia: '+995',
  Kazakhstan: '+7',
  Kyrgyzstan: '+996',
  Tajikistan: '+992',
  Turkmenistan: '+993',
  Uzbekistan: '+998',
};

export const COUNTRY_NAMES_LIST = {
  Russia: 'Россия',
  Azerbaijan: 'Азербайджан',
  Armenia: 'Армения',
  Ukraine: 'Украина',
  Belarus: 'Беларусь',
  Moldova: 'Молдова',
  Latvia: 'Латвия',
  Lithuania: 'Литва',
  Estonia: 'Эстония',
  Georgia: 'Грузия',
  Kazakhstan: 'Казахстан',
  Kyrgyzstan: 'Киргизия',
  Tajikistan: 'Таджикистан',
  Turkmenistan: 'Туркмения',
  Uzbekistan: 'Узбекистан',
};

export const ERROR_TYPES_LIST = {
  nonCyrillic: 'Допустимы только символы кириллицы',
  invalidEmail: 'Неверный адрес электронной почты',
  invalidPassword: 'Необходим минимум 1 спец. символ и 1 цифра. Например: qwerty2#',
  invalidInn: 'Неверный ИНН',
  noSuchEmail: 'Адрес электронной почты не зарегистрирован',
};

// export const USER_ROLES = {
//   user,
//   admin,
// }

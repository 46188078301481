// vvv Utility functions

export function roundToDecimals(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function truncateDecimals(number, digits) {
  let multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
}

export function getPropsByValueInObject(object, value) {
  let propsArr = [];
  let key = '';
  for (key in object) {
    //console.log('key: ' + key + ' value: ' + object[key]);
    if (String(object[key]) === String(value) || String(object[key]) === '') {
      //console.log('key 2: ' + key + ' value: ' + object[key]);
      propsArr.push(key);
    }
  }
  return propsArr;
}

export function trimStringToLength(string, length) {
  return string.substring(0, length);
}

export function validateText(str) {
  if (!str) return false;
  return Boolean(str.length > 1);
}
export function validateText2(str) {
  if (!str) return false;
  return Boolean(str.length > 0);
}
export function validateTextArea(str) {
  if (!str) return false;
  return Boolean(str.length > 20);
}

export function buildOptions(arr) {
  return arr.map((item) => ({ value: item, label: item }));
}

export function getModelsFilters(obj) {
  const arr = [...obj.autocad, ...obj.revit];
  let uniqueArrs = {
    typo: ['Не выбрано'],
    pres: ['Не выбрано'],
    plates: ['Не выбрано'],
  };
  arr.map((a, index) => {
    return Object.keys(a).map((item, i) => {
      if (item === 'typo' || item === 'pres' || item === 'plates') {
        return (uniqueArrs = {
          ...uniqueArrs,
          [item]: !uniqueArrs[item].includes(a[item]) ? [...uniqueArrs[item], a[item]] : [...uniqueArrs[item]],
        });
      }
      return null;
    });
  });
  uniqueArrs.plates.sort((a, b) => {
    return a.split('-')[0] - b.split('-')[0];
  });
  return uniqueArrs;
}

export function invoicesSearch(search, data, field) {
  return search ? data.filter((row) => row[field] === search) : data;
}

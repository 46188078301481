import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../Elements/Modal/ModalContainer';
import commonStyles from '../../../commonUtils/commonStyles';
import { Typography, Grid, Divider } from '@material-ui/core';
import AuthTextInputComponent from '../Elements/AuthTextInput';
import { validateTextArea } from '../../../commonUtils/commonUtils';
import { GlobalContext } from '../../..';
import useServerRequest from '../../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../../constants';
import ButtonComponent from '../Elements/Button/ButtonComponent';

const initialStateObject = {
  toastOpen: false,
  toastMessage: '',
  toastStyle: 'success', //possible styles: "success", "error", "warning", "info"
  toastAutohideDuration: 10000,
  errorText: '',
  message: '',
  messageTheme: '',
};

export default function ConsultantModalComponent({ open, handleClose }) {
  const [user, setUser] = useState({});
  const [state, setState] = useState(initialStateObject);
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: getUserInfo } = useServerRequest(setGlobalLoading);
  const { getRequest: sendSupportMessage } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    getUserInfo(API_ENDPOINTS_LIST.get_user, '', 'GET', true).then((res) => {
      if (res && !res.code) {
        setUser(res.result);
      } else {
        setGlobalState((prev) => ({
          ...prev,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
    // eslint-disable-next-line
  }, [getUserInfo]);

  const submitHandler = () => {
    const isValid = validateTextArea(state.message);
    if (isValid) {
      sendSupportMessage(
        API_ENDPOINTS_LIST.support_message,
        JSON.stringify({
          subject: state.messageTheme,
          message: state.message,
        })
      ).then((res) => {
        if (res && res.status === 200) {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Сообщение успешно отправлено!',
            toastStyle: 'success',
            loaderEnabled: false,
          }));
          handleClose();
          setState((prev) => ({ ...prev, errorText: '', message: '', messageTheme: '' }));
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      });
    } else {
      setState((prev) => ({ ...prev, errorText: 'Минимальная длина сообщения 20 символов' }));
    }
  };

  const inputChangeHandler = (e, type) => {
    const data = e.target.value;
    //console.log(data);
    setState((prev) => ({ ...prev, [type]: data }));
  };
  return (
    <>
      <ModalContainer open={open} handleClose={handleClose}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...getStyles().headerText,
              }}
            >
              Напишите сообщение нашему консультанту
            </Typography>
          </Grid>
          <Divider variant="middle" />
          <Grid item xs={12}>
            <AuthTextInputComponent
              id="lastName"
              style={{ marginBottom: '30px' }}
              type="text"
              label="Тема сообщения"
              variant="outlined"
              value={state.messageTheme}
              onChange={(e) => inputChangeHandler(e, 'messageTheme')}
              //   error={!!state.errorText}
              //   helperText={state.errorText}
            />
            <AuthTextInputComponent
              id="lastName"
              style={{ marginBottom: '30px' }}
              type="text"
              label="Сообщение"
              variant="outlined"
              value={state.message}
              multiline
              rows={4}
              onChange={(e) => inputChangeHandler(e, 'message')}
              error={!!state.errorText}
              helperText={state.errorText}
            />
            <Typography
              style={{
                ...commonStyles.text.robotoRegular18,
              }}
            >
              Ответ на ваше обращение будет отправлен на почту:{' '}
              <Typography
                display="inline"
                component="span"
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().headerText,
                }}
              >
                {user?.email}
              </Typography>
            </Typography>
          </Grid>

          <Grid container spacing={2} justify="flex-end" style={getStyles().marginTop}>
            <Grid item>
              <ButtonComponent size="large" variant="outlined" color="primary" onClick={handleClose}>
                Отменить
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent size="large" variant="contained" color="primary" onClick={submitHandler}>
                Отправить
              </ButtonComponent>
            </Grid>
          </Grid>
        </Grid>
      </ModalContainer>
    </>
  );
}

const getStyles = () => {
  const styles = {
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
      textAlign: 'center',
    },
    marginTop: {
      marginTop: 20,
    },
  };
  return styles;
};

ConsultantModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

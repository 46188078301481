function NM(fun, X_s) {
  // метод ниютона
  const eps = 1e-5;
  const h = 1e-3;
  let X = X_s;
  let dr = (2 * h * fun(X)) / (fun(X + h) - fun(X - h));
  X = X - dr;
  while (Math.abs(dr) > eps) {
    dr = (2 * h * fun(X)) / (fun(X + h) - fun(X - h));
    X = X - dr;
  }
  return X;
}
//module.exports = NM;
export default NM;

import React from 'react';
//import commonStyles from '../../../commonUtils/commonStyles';
import { IconButton, Badge } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { withStyles } from '@material-ui/styles';

import MainClass from '../../../main/MainClass';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: '22px',
    border: '2px solid',
    padding: '4px',
  },
}))(Badge);

export default function ShoppingCartComponent({ ...restProps }) {
  let shoppingCartCounter = MainClass.shoppingCartGlobalData.cartItemsNum;

  return (
    <IconButton
      aria-label="shopping-cart"
      /*
      style={{
        border: '1px solid',
        borderColor: 'red',
      }}
      
      onClick={() => {
        console.log('Shopping cart button pressed');
      }}
      */
      variant="contained"
      disabled={false}
      {...restProps}
    >
      <StyledBadge badgeContent={shoppingCartCounter} color="secondary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
}

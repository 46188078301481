let data = [
  {
    name: 'Насыщенный пар',
    density: [
      { t: 105, value: 0.701 },
      { t: 106, value: 0.724 },
      { t: 107, value: 0.747 },
      { t: 108, value: 0.771 },
      { t: 109, value: 0.795 },
      { t: 110, value: 0.821 },
      { t: 111, value: 0.846 },
      { t: 112, value: 0.873 },
      { t: 113, value: 0.9 },
      { t: 114, value: 0.927 },
      { t: 115, value: 0.956 },
      { t: 116, value: 0.985 },
      { t: 117, value: 1.015 },
      { t: 118, value: 1.045 },
      { t: 119, value: 1.076 },
      { t: 120, value: 1.108 },
      { t: 121, value: 1.141 },
      { t: 122, value: 1.174 },
      { t: 123, value: 1.209 },
      { t: 124, value: 1.244 },
      { t: 125, value: 1.279 },
      { t: 126, value: 1.316 },
      { t: 127, value: 1.353 },
      { t: 128, value: 1.391 },
      { t: 129, value: 1.43 },
      { t: 130, value: 1.47 },
      { t: 131, value: 1.511 },
      { t: 132, value: 1.553 },
      { t: 133, value: 1.595 },
      { t: 134, value: 1.639 },
      { t: 135, value: 1.683 },
      { t: 136, value: 1.729 },
      { t: 137, value: 1.775 },
      { t: 138, value: 1.822 },
      { t: 139, value: 1.87 },
      { t: 140, value: 1.919 },
      { t: 141, value: 1.97 },
      { t: 142, value: 2.021 },
      { t: 143, value: 2.073 },
      { t: 144, value: 2.126 },
      { t: 145, value: 2.181 },
      { t: 146, value: 2.236 },
      { t: 147, value: 2.293 },
      { t: 148, value: 2.35 },
      { t: 149, value: 2.409 },
      { t: 150, value: 2.469 },
      { t: 151, value: 2.529 },
      { t: 152, value: 2.592 },
      { t: 153, value: 2.655 },
      { t: 154, value: 2.719 },
      { t: 155, value: 2.785 },
      { t: 156, value: 2.852 },
      { t: 157, value: 2.92 },
      { t: 158, value: 2.989 },
      { t: 159, value: 3.06 },
      { t: 160, value: 3.131 },
      { t: 161, value: 3.204 },
      { t: 162, value: 3.279 },
      { t: 163, value: 3.355 },
      { t: 164, value: 3.432 },
      { t: 165, value: 3.51 },
      { t: 166, value: 3.59 },
      { t: 167, value: 3.671 },
      { t: 168, value: 3.753 },
      { t: 169, value: 3.837 },
      { t: 170, value: 3.922 },
      { t: 171, value: 4.009 },
      { t: 172, value: 4.097 },
      { t: 173, value: 4.187 },
      { t: 174, value: 4.278 },
      { t: 175, value: 4.37 },
      { t: 176, value: 4.464 },
      { t: 177, value: 4.56 },
      { t: 178, value: 4.657 },
      { t: 179, value: 4.756 },
      { t: 180, value: 4.856 },
      { t: 181, value: 4.958 },
      { t: 182, value: 5.061 },
      { t: 183, value: 5.166 },
      { t: 184, value: 5.273 },
      { t: 185, value: 5.381 },
      { t: 186, value: 5.491 },
      { t: 187, value: 5.603 },
      { t: 188, value: 5.716 },
      { t: 189, value: 5.831 },
      { t: 190, value: 5.947 },
    ],
    specific_heat: [
      { t: 105, value: 2073 },
      { t: 106, value: 2076 },
      { t: 107, value: 2079 },
      { t: 108, value: 2082 },
      { t: 109, value: 2086 },
      { t: 110, value: 2089 },
      { t: 111, value: 2092 },
      { t: 112, value: 2096 },
      { t: 113, value: 2099 },
      { t: 114, value: 2102 },
      { t: 115, value: 2106 },
      { t: 116, value: 2109 },
      { t: 117, value: 2113 },
      { t: 118, value: 2117 },
      { t: 119, value: 2120 },
      { t: 120, value: 2124 },
      { t: 121, value: 2128 },
      { t: 122, value: 2132 },
      { t: 123, value: 2135 },
      { t: 124, value: 2139 },
      { t: 125, value: 2143 },
      { t: 126, value: 2147 },
      { t: 127, value: 2151 },
      { t: 128, value: 2155 },
      { t: 129, value: 2159 },
      { t: 130, value: 2164 },
      { t: 131, value: 2168 },
      { t: 132, value: 2172 },
      { t: 133, value: 2177 },
      { t: 134, value: 2181 },
      { t: 135, value: 2185 },
      { t: 136, value: 2190 },
      { t: 137, value: 2195 },
      { t: 138, value: 2199 },
      { t: 139, value: 2204 },
      { t: 140, value: 2209 },
      { t: 141, value: 2213 },
      { t: 142, value: 2218 },
      { t: 143, value: 2223 },
      { t: 144, value: 2228 },
      { t: 145, value: 2234 },
      { t: 146, value: 2239 },
      { t: 147, value: 2244 },
      { t: 148, value: 2249 },
      { t: 149, value: 2255 },
      { t: 150, value: 2260 },
      { t: 151, value: 2266 },
      { t: 152, value: 2271 },
      { t: 153, value: 2277 },
      { t: 154, value: 2283 },
      { t: 155, value: 2289 },
      { t: 156, value: 2295 },
      { t: 157, value: 2301 },
      { t: 158, value: 2307 },
      { t: 159, value: 2313 },
      { t: 160, value: 2320 },
      { t: 161, value: 2326 },
      { t: 162, value: 2333 },
      { t: 163, value: 2340 },
      { t: 164, value: 2346 },
      { t: 165, value: 2353 },
      { t: 166, value: 2360 },
      { t: 167, value: 2367 },
      { t: 168, value: 2375 },
      { t: 169, value: 2382 },
      { t: 170, value: 2389 },
      { t: 171, value: 2397 },
      { t: 172, value: 2405 },
      { t: 173, value: 2413 },
      { t: 174, value: 2421 },
      { t: 175, value: 2429 },
      { t: 176, value: 2437 },
      { t: 177, value: 2446 },
      { t: 178, value: 2454 },
      { t: 179, value: 2463 },
      { t: 180, value: 2472 },
      { t: 181, value: 2481 },
      { t: 182, value: 2490 },
      { t: 183, value: 2500 },
      { t: 184, value: 2509 },
      { t: 185, value: 2519 },
      { t: 186, value: 2529 },
      { t: 187, value: 2539 },
      { t: 188, value: 2550 },
      { t: 189, value: 2560 },
      { t: 190, value: 2571 },
    ],
    thermal_conductivity: [
      { t: 105, value: 0.0251 },
      { t: 106, value: 0.0252 },
      { t: 107, value: 0.0253 },
      { t: 108, value: 0.0254 },
      { t: 109, value: 0.0255 },
      { t: 110, value: 0.0256 },
      { t: 111, value: 0.0256 },
      { t: 112, value: 0.0257 },
      { t: 113, value: 0.0258 },
      { t: 114, value: 0.0259 },
      { t: 115, value: 0.026 },
      { t: 116, value: 0.0261 },
      { t: 117, value: 0.0262 },
      { t: 118, value: 0.0263 },
      { t: 119, value: 0.0264 },
      { t: 120, value: 0.0265 },
      { t: 121, value: 0.0266 },
      { t: 122, value: 0.0267 },
      { t: 123, value: 0.0268 },
      { t: 124, value: 0.0269 },
      { t: 125, value: 0.027 },
      { t: 126, value: 0.0271 },
      { t: 127, value: 0.0272 },
      { t: 128, value: 0.0273 },
      { t: 129, value: 0.0274 },
      { t: 130, value: 0.0275 },
      { t: 131, value: 0.0276 },
      { t: 132, value: 0.0277 },
      { t: 133, value: 0.0278 },
      { t: 134, value: 0.0279 },
      { t: 135, value: 0.028 },
      { t: 136, value: 0.0282 },
      { t: 137, value: 0.0283 },
      { t: 138, value: 0.0284 },
      { t: 139, value: 0.0285 },
      { t: 140, value: 0.0286 },
      { t: 141, value: 0.0287 },
      { t: 142, value: 0.0288 },
      { t: 143, value: 0.0289 },
      { t: 144, value: 0.0291 },
      { t: 145, value: 0.0292 },
      { t: 146, value: 0.0293 },
      { t: 147, value: 0.0294 },
      { t: 148, value: 0.0295 },
      { t: 149, value: 0.0296 },
      { t: 150, value: 0.0298 },
      { t: 151, value: 0.0299 },
      { t: 152, value: 0.03 },
      { t: 153, value: 0.0301 },
      { t: 154, value: 0.0303 },
      { t: 155, value: 0.0304 },
      { t: 156, value: 0.0305 },
      { t: 157, value: 0.0306 },
      { t: 158, value: 0.0308 },
      { t: 159, value: 0.0309 },
      { t: 160, value: 0.031 },
      { t: 161, value: 0.0311 },
      { t: 162, value: 0.0313 },
      { t: 163, value: 0.0314 },
      { t: 164, value: 0.0315 },
      { t: 165, value: 0.0317 },
      { t: 166, value: 0.0318 },
      { t: 167, value: 0.0319 },
      { t: 168, value: 0.0321 },
      { t: 169, value: 0.0322 },
      { t: 170, value: 0.0324 },
      { t: 171, value: 0.0325 },
      { t: 172, value: 0.0326 },
      { t: 173, value: 0.0328 },
      { t: 174, value: 0.0329 },
      { t: 175, value: 0.0331 },
      { t: 176, value: 0.0332 },
      { t: 177, value: 0.0334 },
      { t: 178, value: 0.0335 },
      { t: 179, value: 0.0337 },
      { t: 180, value: 0.0338 },
      { t: 181, value: 0.034 },
      { t: 182, value: 0.0341 },
      { t: 183, value: 0.0343 },
      { t: 184, value: 0.0344 },
      { t: 185, value: 0.0346 },
      { t: 186, value: 0.0347 },
      { t: 187, value: 0.0349 },
      { t: 188, value: 0.0351 },
      { t: 189, value: 0.0352 },
      { t: 190, value: 0.0354 },
    ],
    viscosity: [
      { t: 105, value: 0.00001223 },
      { t: 106, value: 0.00001226 },
      { t: 107, value: 0.0000123 },
      { t: 108, value: 0.00001234 },
      { t: 109, value: 0.00001238 },
      { t: 110, value: 0.00001242 },
      { t: 111, value: 0.000012460000000000001 },
      { t: 112, value: 0.000012489999999999999 },
      { t: 113, value: 0.00001253 },
      { t: 114, value: 0.00001257 },
      { t: 115, value: 0.00001261 },
      { t: 116, value: 0.00001265 },
      { t: 117, value: 0.00001269 },
      { t: 118, value: 0.00001272 },
      { t: 119, value: 0.00001276 },
      { t: 120, value: 0.000012800000000000001 },
      { t: 121, value: 0.00001284 },
      { t: 122, value: 0.00001288 },
      { t: 123, value: 0.00001291 },
      { t: 124, value: 0.00001295 },
      { t: 125, value: 0.00001299 },
      { t: 126, value: 0.00001303 },
      { t: 127, value: 0.00001307 },
      { t: 128, value: 0.0000131 },
      { t: 129, value: 0.000013140000000000001 },
      { t: 130, value: 0.000013180000000000001 },
      { t: 131, value: 0.00001322 },
      { t: 132, value: 0.00001325 },
      { t: 133, value: 0.00001329 },
      { t: 134, value: 0.00001333 },
      { t: 135, value: 0.00001337 },
      { t: 136, value: 0.0000134 },
      { t: 137, value: 0.00001344 },
      { t: 138, value: 0.000013480000000000001 },
      { t: 139, value: 0.000013520000000000001 },
      { t: 140, value: 0.000013549999999999999 },
      { t: 141, value: 0.00001359 },
      { t: 142, value: 0.00001363 },
      { t: 143, value: 0.00001366 },
      { t: 144, value: 0.000013700000000000001 },
      { t: 145, value: 0.00001374 },
      { t: 146, value: 0.00001378 },
      { t: 147, value: 0.00001381 },
      { t: 148, value: 0.00001385 },
      { t: 149, value: 0.000013889999999999999 },
      { t: 150, value: 0.00001392 },
      { t: 151, value: 0.00001396 },
      { t: 152, value: 0.000014 },
      { t: 153, value: 0.000014030000000000001 },
      { t: 154, value: 0.000014069999999999999 },
      { t: 155, value: 0.000014109999999999999 },
      { t: 156, value: 0.00001414 },
      { t: 157, value: 0.00001418 },
      { t: 158, value: 0.00001422 },
      { t: 159, value: 0.00001425 },
      { t: 160, value: 0.00001429 },
      { t: 161, value: 0.00001433 },
      { t: 162, value: 0.00001436 },
      { t: 163, value: 0.0000144 },
      { t: 164, value: 0.00001443 },
      { t: 165, value: 0.00001447 },
      { t: 166, value: 0.00001451 },
      { t: 167, value: 0.000014540000000000001 },
      { t: 168, value: 0.00001458 },
      { t: 169, value: 0.000014619999999999999 },
      { t: 170, value: 0.00001465 },
      { t: 171, value: 0.00001469 },
      { t: 172, value: 0.00001472 },
      { t: 173, value: 0.000014760000000000001 },
      { t: 174, value: 0.0000148 },
      { t: 175, value: 0.000014829999999999999 },
      { t: 176, value: 0.00001487 },
      { t: 177, value: 0.0000149 },
      { t: 178, value: 0.000014940000000000001 },
      { t: 179, value: 0.00001497 },
      { t: 180, value: 0.00001501 },
      { t: 181, value: 0.00001505 },
      { t: 182, value: 0.00001508 },
      { t: 183, value: 0.00001512 },
      { t: 184, value: 0.00001515 },
      { t: 185, value: 0.00001519 },
      { t: 186, value: 0.00001522 },
      { t: 187, value: 0.00001526 },
      { t: 188, value: 0.00001529 },
      { t: 189, value: 0.00001533 },
      { t: 190, value: 0.000015360000000000002 },
    ],
    condensation_heat: [
      { t: 105, value: 2246340 },
      { t: 106, value: 2243510 },
      { t: 107, value: 2240670 },
      { t: 108, value: 2237820 },
      { t: 109, value: 2234960 },
      { t: 110, value: 2232090 },
      { t: 111, value: 2229210 },
      { t: 112, value: 2226330 },
      { t: 113, value: 2223430 },
      { t: 114, value: 2220530 },
      { t: 115, value: 2217610 },
      { t: 116, value: 2214690 },
      { t: 117, value: 2211750 },
      { t: 118, value: 2208810 },
      { t: 119, value: 2205860 },
      { t: 120, value: 2202900 },
      { t: 121, value: 2199930 },
      { t: 122, value: 2196950 },
      { t: 123, value: 2193960 },
      { t: 124, value: 2190960 },
      { t: 125, value: 2187950 },
      { t: 126, value: 2184930 },
      { t: 127, value: 2181900 },
      { t: 128, value: 2178870 },
      { t: 129, value: 2175820 },
      { t: 130, value: 2172760 },
      { t: 131, value: 2169690 },
      { t: 132, value: 2166610 },
      { t: 133, value: 2163530 },
      { t: 134, value: 2160430 },
      { t: 135, value: 2157320 },
      { t: 136, value: 2154200 },
      { t: 137, value: 2151070 },
      { t: 138, value: 2147930 },
      { t: 139, value: 2144790 },
      { t: 140, value: 2141630 },
      { t: 141, value: 2138460 },
      { t: 142, value: 2135280 },
      { t: 143, value: 2132080 },
      { t: 144, value: 2128880 },
      { t: 145, value: 2125670 },
      { t: 146, value: 2122450 },
      { t: 147, value: 2119220 },
      { t: 148, value: 2115970 },
      { t: 149, value: 2112720 },
      { t: 150, value: 2109450 },
      { t: 151, value: 2106170 },
      { t: 152, value: 2102890 },
      { t: 153, value: 2099590 },
      { t: 154, value: 2096280 },
      { t: 155, value: 2092960 },
      { t: 156, value: 2089630 },
      { t: 157, value: 2086280 },
      { t: 158, value: 2082929 },
      { t: 159, value: 2079560 },
      { t: 160, value: 2076190 },
      { t: 161, value: 2072800 },
      { t: 162, value: 2069400 },
      { t: 163, value: 2065989 },
      { t: 164, value: 2062560 },
      { t: 165, value: 2059130 },
      { t: 166, value: 2055698 },
      { t: 167, value: 2052230 },
      { t: 168, value: 2048760 },
      { t: 169, value: 2045270 },
      { t: 170, value: 2041780 },
      { t: 171, value: 2038280 },
      { t: 172, value: 2034760 },
      { t: 173, value: 2031230 },
      { t: 174, value: 2027690 },
      { t: 175, value: 2024130 },
      { t: 176, value: 2020570 },
      { t: 177, value: 2016990 },
      { t: 178, value: 2013400 },
      { t: 179, value: 2009790 },
      { t: 180, value: 2006180 },
      { t: 181, value: 2002550 },
      { t: 182, value: 1998910 },
      { t: 183, value: 1995260 },
      { t: 184, value: 1991590 },
      { t: 185, value: 1987910 },
      { t: 186, value: 1984220 },
      { t: 187, value: 1980520 },
      { t: 188, value: 1976800 },
      { t: 189, value: 1973070 },
      { t: 190, value: 1969320 },
    ],
  },
  {
    name: 'Вода',
    density: [
      {
        t: 0,
        value: 1000.4,
      },
      {
        t: 15,
        value: 998.8,
      },
      {
        t: 30,
        value: 995.4,
      },
      {
        t: 45,
        value: 990.3,
      },
      {
        t: 60,
        value: 983.5,
      },
      {
        t: 75,
        value: 975.4,
      },
      {
        t: 90,
        value: 965.9,
      },
      {
        t: 105,
        value: 955.2,
      },
      {
        t: 120,
        value: 943.5,
      },
      {
        t: 135,
        value: 930.8,
      },
      {
        t: 150,
        value: 917.5,
      },
      {
        t: 165,
        value: 903.3,
      },
      {
        t: 180,
        value: 888.6,
      },
    ],
    specific_heat: [
      {
        t: 0,
        value: 4214.3,
      },
      {
        t: 15,
        value: 4192.5,
      },
      {
        t: 30,
        value: 4180.5,
      },
      {
        t: 45,
        value: 4177.7,
      },
      {
        t: 60,
        value: 4181.8,
      },
      {
        t: 75,
        value: 4191.2,
      },
      {
        t: 90,
        value: 4205,
      },
      {
        t: 105,
        value: 4223.1,
      },
      {
        t: 120,
        value: 4245.5,
      },
      {
        t: 135,
        value: 4273.3,
      },
      {
        t: 150,
        value: 4307.7,
      },
      {
        t: 165,
        value: 4351.1,
      },
      {
        t: 180,
        value: 4406.6,
      },
    ],
    thermal_conductivity: [
      {
        t: 0,
        value: 0.565,
      },
      {
        t: 15,
        value: 0.591,
      },
      {
        t: 30,
        value: 0.619,
      },
      {
        t: 45,
        value: 0.642,
      },
      {
        t: 60,
        value: 0.658,
      },
      {
        t: 75,
        value: 0.67,
      },
      {
        t: 90,
        value: 0.678,
      },
      {
        t: 105,
        value: 0.68,
      },
      {
        t: 120,
        value: 0.688,
      },
      {
        t: 135,
        value: 0.689,
      },
      {
        t: 150,
        value: 0.688,
      },
      {
        t: 165,
        value: 0.687,
      },
      {
        t: 180,
        value: 0.68,
      },
    ],
    viscosity: [
      {
        t: 0,
        value: 0.0017698,
      },
      {
        t: 15,
        value: 0.0011612,
      },
      {
        t: 30,
        value: 0.0008038,
      },
      {
        t: 45,
        value: 0.0006013,
      },
      {
        t: 60,
        value: 0.0004758,
      },
      {
        t: 75,
        value: 0.0003876,
      },
      {
        t: 90,
        value: 0.0003197,
      },
      {
        t: 105,
        value: 0.0002679,
      },
      {
        t: 120,
        value: 0.0002306,
      },
      {
        t: 135,
        value: 0.0002047,
      },
      {
        t: 150,
        value: 0.0001859,
      },
      {
        t: 165,
        value: 0.0001703,
      },
      {
        t: 180,
        value: 0.0001545,
      },
    ],
  },
  {
    name: 'Этиленгликоль-30%',
    density: [
      {
        t: -10,
        value: 1057.1,
      },
      {
        t: 0,
        value: 1053.9,
      },
      {
        t: 10,
        value: 1050.4,
      },
      {
        t: 20,
        value: 1046.3,
      },
      {
        t: 30,
        value: 1041.9,
      },
      {
        t: 40,
        value: 1037.2,
      },
      {
        t: 50,
        value: 1032.4,
      },
      {
        t: 60,
        value: 1027.4,
      },
      {
        t: 70,
        value: 1022.3,
      },
      {
        t: 80,
        value: 1017.1,
      },
      {
        t: 90,
        value: 1012,
      },
      {
        t: 100,
        value: 1006.8,
      },
      {
        t: 110,
        value: 1001.7,
      },
      {
        t: 120,
        value: 996.6,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3609.2,
      },
      {
        t: 0,
        value: 3627.1,
      },
      {
        t: 10,
        value: 3649.7,
      },
      {
        t: 20,
        value: 3672.2,
      },
      {
        t: 30,
        value: 3696,
      },
      {
        t: 40,
        value: 3720.8,
      },
      {
        t: 50,
        value: 3746.1,
      },
      {
        t: 60,
        value: 3771.9,
      },
      {
        t: 70,
        value: 3797.9,
      },
      {
        t: 80,
        value: 3823.9,
      },
      {
        t: 90,
        value: 3849.8,
      },
      {
        t: 100,
        value: 3875.5,
      },
      {
        t: 110,
        value: 3901,
      },
      {
        t: 120,
        value: 3926.1,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.463,
      },
      {
        t: 0,
        value: 0.469,
      },
      {
        t: 10,
        value: 0.475,
      },
      {
        t: 20,
        value: 0.48,
      },
      {
        t: 30,
        value: 0.483,
      },
      {
        t: 40,
        value: 0.486,
      },
      {
        t: 50,
        value: 0.489,
      },
      {
        t: 60,
        value: 0.492,
      },
      {
        t: 70,
        value: 0.494,
      },
      {
        t: 80,
        value: 0.495,
      },
      {
        t: 90,
        value: 0.496,
      },
      {
        t: 100,
        value: 0.497,
      },
      {
        t: 110,
        value: 0.498,
      },
      {
        t: 120,
        value: 0.499,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.00754,
      },
      {
        t: 0,
        value: 0.00468,
      },
      {
        t: 10,
        value: 0.00322,
      },
      {
        t: 20,
        value: 0.00234,
      },
      {
        t: 30,
        value: 0.00177,
      },
      {
        t: 40,
        value: 0.00139,
      },
      {
        t: 50,
        value: 0.00112,
      },
      {
        t: 60,
        value: 0.00094,
      },
      {
        t: 70,
        value: 0.0008,
      },
      {
        t: 80,
        value: 0.0007,
      },
      {
        t: 90,
        value: 0.00062,
      },
      {
        t: 100,
        value: 0.00056,
      },
      {
        t: 110,
        value: 0.00051,
      },
      {
        t: 120,
        value: 0.00048,
      },
    ],
  },
  {
    name: 'Этиленгликоль-35%',
    density: [
      {
        t: -10,
        value: 1066.6,
      },
      {
        t: 0,
        value: 1062.8,
      },
      {
        t: 10,
        value: 1058.8,
      },
      {
        t: 20,
        value: 1054.4,
      },
      {
        t: 30,
        value: 1049.7,
      },
      {
        t: 40,
        value: 1044.7,
      },
      {
        t: 50,
        value: 1039.5,
      },
      {
        t: 60,
        value: 1034.2,
      },
      {
        t: 70,
        value: 1028.8,
      },
      {
        t: 80,
        value: 1023.4,
      },
      {
        t: 90,
        value: 1018,
      },
      {
        t: 100,
        value: 1012.6,
      },
      {
        t: 110,
        value: 1007.2,
      },
      {
        t: 120,
        value: 1001.9,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3506.3,
      },
      {
        t: 0,
        value: 3531.9,
      },
      {
        t: 10,
        value: 3557.4,
      },
      {
        t: 20,
        value: 3584.4,
      },
      {
        t: 30,
        value: 3612.4,
      },
      {
        t: 40,
        value: 3641,
      },
      {
        t: 50,
        value: 3670.1,
      },
      {
        t: 60,
        value: 3699.4,
      },
      {
        t: 70,
        value: 3738.6,
      },
      {
        t: 80,
        value: 3757.7,
      },
      {
        t: 90,
        value: 3786.5,
      },
      {
        t: 100,
        value: 3814.9,
      },
      {
        t: 110,
        value: 3843,
      },
      {
        t: 120,
        value: 3870.6,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.45,
      },
      {
        t: 0,
        value: 0.455,
      },
      {
        t: 10,
        value: 0.458,
      },
      {
        t: 20,
        value: 0.461,
      },
      {
        t: 30,
        value: 0.463,
      },
      {
        t: 40,
        value: 0.465,
      },
      {
        t: 50,
        value: 0.466,
      },
      {
        t: 60,
        value: 0.467,
      },
      {
        t: 70,
        value: 0.468,
      },
      {
        t: 80,
        value: 0.468,
      },
      {
        t: 90,
        value: 0.468,
      },
      {
        t: 100,
        value: 0.468,
      },
      {
        t: 110,
        value: 0.468,
      },
      {
        t: 120,
        value: 0.468,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.00897,
      },
      {
        t: 0,
        value: 0.0055,
      },
      {
        t: 10,
        value: 0.00376,
      },
      {
        t: 20,
        value: 0.0027,
      },
      {
        t: 30,
        value: 0.00203,
      },
      {
        t: 40,
        value: 0.00139,
      },
      {
        t: 50,
        value: 0.00158,
      },
      {
        t: 60,
        value: 0.00105,
      },
      {
        t: 70,
        value: 0.00089,
      },
      {
        t: 80,
        value: 0.00077,
      },
      {
        t: 90,
        value: 0.00068,
      },
      {
        t: 100,
        value: 0.00061,
      },
      {
        t: 110,
        value: 0.00055,
      },
      {
        t: 120,
        value: 0.00051,
      },
    ],
  },
  {
    name: 'Этиленгликоль-40%',
    density: [
      {
        t: -10,
        value: 1075.9,
      },
      {
        t: 0,
        value: 1071.7,
      },
      {
        t: 10,
        value: 1067.3,
      },
      {
        t: 20,
        value: 1062.5,
      },
      {
        t: 30,
        value: 1057.4,
      },
      {
        t: 40,
        value: 1052.1,
      },
      {
        t: 50,
        value: 1046.6,
      },
      {
        t: 60,
        value: 1041,
      },
      {
        t: 70,
        value: 1035.3,
      },
      {
        t: 80,
        value: 1029.6,
      },
      {
        t: 90,
        value: 1024,
      },
      {
        t: 100,
        value: 1018.4,
      },
      {
        t: 110,
        value: 1012.8,
      },
      {
        t: 120,
        value: 1007.3,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3403.4,
      },
      {
        t: 0,
        value: 3434.7,
      },
      {
        t: 10,
        value: 3465,
      },
      {
        t: 20,
        value: 3496.5,
      },
      {
        t: 30,
        value: 3528.7,
      },
      {
        t: 40,
        value: 3561.3,
      },
      {
        t: 50,
        value: 3594.1,
      },
      {
        t: 60,
        value: 3626.8,
      },
      {
        t: 70,
        value: 3659.3,
      },
      {
        t: 80,
        value: 3691.4,
      },
      {
        t: 90,
        value: 3723.2,
      },
      {
        t: 100,
        value: 3754.4,
      },
      {
        t: 110,
        value: 3785.1,
      },
      {
        t: 120,
        value: 3815.1,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.438,
      },
      {
        t: 0,
        value: 0.44,
      },
      {
        t: 10,
        value: 0.441,
      },
      {
        t: 20,
        value: 0.442,
      },
      {
        t: 30,
        value: 0.443,
      },
      {
        t: 40,
        value: 0.443,
      },
      {
        t: 50,
        value: 0.443,
      },
      {
        t: 60,
        value: 0.442,
      },
      {
        t: 70,
        value: 0.442,
      },
      {
        t: 80,
        value: 0.441,
      },
      {
        t: 90,
        value: 0.44,
      },
      {
        t: 100,
        value: 0.439,
      },
      {
        t: 110,
        value: 0.437,
      },
      {
        t: 120,
        value: 0.436,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0106,
      },
      {
        t: 0,
        value: 0.00648,
      },
      {
        t: 10,
        value: 0.00438,
      },
      {
        t: 20,
        value: 0.00312,
      },
      {
        t: 30,
        value: 0.00232,
      },
      {
        t: 40,
        value: 0.00179,
      },
      {
        t: 50,
        value: 0.00143,
      },
      {
        t: 60,
        value: 0.00117,
      },
      {
        t: 70,
        value: 0.00099,
      },
      {
        t: 80,
        value: 0.00085,
      },
      {
        t: 90,
        value: 0.00074,
      },
      {
        t: 100,
        value: 0.00066,
      },
      {
        t: 110,
        value: 0.0006,
      },
      {
        t: 120,
        value: 0.00055,
      },
    ],
  },
  {
    name: 'Этиленгликоль-45%',
    density: [
      {
        t: -10,
        value: 1085.2,
      },
      {
        t: 0,
        value: 1080.6,
      },
      {
        t: 10,
        value: 1075.8,
      },
      {
        t: 20,
        value: 1070.6,
      },
      {
        t: 30,
        value: 1065.1,
      },
      {
        t: 40,
        value: 1059.5,
      },
      {
        t: 50,
        value: 1053.7,
      },
      {
        t: 60,
        value: 1047.8,
      },
      {
        t: 70,
        value: 1041.8,
      },
      {
        t: 80,
        value: 1035.9,
      },
      {
        t: 90,
        value: 1030,
      },
      {
        t: 100,
        value: 1024.1,
      },
      {
        t: 110,
        value: 1018.3,
      },
      {
        t: 120,
        value: 1012.6,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3300.5,
      },
      {
        t: 0,
        value: 3337.6,
      },
      {
        t: 10,
        value: 3372.7,
      },
      {
        t: 20,
        value: 3408.7,
      },
      {
        t: 30,
        value: 3445.1,
      },
      {
        t: 40,
        value: 3481.6,
      },
      {
        t: 50,
        value: 3518.1,
      },
      {
        t: 60,
        value: 3554.2,
      },
      {
        t: 70,
        value: 3590,
      },
      {
        t: 80,
        value: 3625.2,
      },
      {
        t: 90,
        value: 3659.9,
      },
      {
        t: 100,
        value: 3693.8,
      },
      {
        t: 110,
        value: 3727.1,
      },
      {
        t: 120,
        value: 3759.7,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.425,
      },
      {
        t: 0,
        value: 0.425,
      },
      {
        t: 10,
        value: 0.425,
      },
      {
        t: 20,
        value: 0.424,
      },
      {
        t: 30,
        value: 0.423,
      },
      {
        t: 40,
        value: 0.421,
      },
      {
        t: 50,
        value: 0.42,
      },
      {
        t: 60,
        value: 0.418,
      },
      {
        t: 70,
        value: 0.416,
      },
      {
        t: 80,
        value: 0.414,
      },
      {
        t: 90,
        value: 0.411,
      },
      {
        t: 100,
        value: 0.409,
      },
      {
        t: 110,
        value: 0.407,
      },
      {
        t: 120,
        value: 0.405,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0127,
      },
      {
        t: 0,
        value: 0.00763,
      },
      {
        t: 10,
        value: 0.00511,
      },
      {
        t: 20,
        value: 0.00361,
      },
      {
        t: 30,
        value: 0.00266,
      },
      {
        t: 40,
        value: 0.00204,
      },
      {
        t: 50,
        value: 0.00162,
      },
      {
        t: 60,
        value: 0.00132,
      },
      {
        t: 70,
        value: 0.0011,
      },
      {
        t: 80,
        value: 0.00094,
      },
      {
        t: 90,
        value: 0.00082,
      },
      {
        t: 100,
        value: 0.00072,
      },
      {
        t: 110,
        value: 0.00065,
      },
      {
        t: 120,
        value: 0.0006,
      },
    ],
  },
  {
    name: 'Этиленгликоль-50%',
    density: [
      {
        t: -10,
        value: 1094.6,
      },
      {
        t: 0,
        value: 1089.5,
      },
      {
        t: 10,
        value: 1084.3,
      },
      {
        t: 20,
        value: 1078.7,
      },
      {
        t: 30,
        value: 1072.9,
      },
      {
        t: 40,
        value: 1066.9,
      },
      {
        t: 50,
        value: 1060.8,
      },
      {
        t: 60,
        value: 1054.6,
      },
      {
        t: 70,
        value: 1048.4,
      },
      {
        t: 80,
        value: 1042.2,
      },
      {
        t: 90,
        value: 1036,
      },
      {
        t: 100,
        value: 1029.9,
      },
      {
        t: 110,
        value: 1023.9,
      },
      {
        t: 120,
        value: 1018,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3197.6,
      },
      {
        t: 0,
        value: 3240.4,
      },
      {
        t: 10,
        value: 3280.4,
      },
      {
        t: 20,
        value: 3320.8,
      },
      {
        t: 30,
        value: 3361.4,
      },
      {
        t: 40,
        value: 3401.9,
      },
      {
        t: 50,
        value: 3442,
      },
      {
        t: 60,
        value: 3481.7,
      },
      {
        t: 70,
        value: 3520.7,
      },
      {
        t: 80,
        value: 3559,
      },
      {
        t: 90,
        value: 3596.5,
      },
      {
        t: 100,
        value: 3633.3,
      },
      {
        t: 110,
        value: 3669.2,
      },
      {
        t: 120,
        value: 3704.2,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.412,
      },
      {
        t: 0,
        value: 0.41,
      },
      {
        t: 10,
        value: 0.408,
      },
      {
        t: 20,
        value: 0.405,
      },
      {
        t: 30,
        value: 0.402,
      },
      {
        t: 40,
        value: 0.399,
      },
      {
        t: 50,
        value: 0.396,
      },
      {
        t: 60,
        value: 0.393,
      },
      {
        t: 70,
        value: 0.39,
      },
      {
        t: 80,
        value: 0.386,
      },
      {
        t: 90,
        value: 0.383,
      },
      {
        t: 100,
        value: 0.38,
      },
      {
        t: 110,
        value: 0.377,
      },
      {
        t: 120,
        value: 0.373,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0151,
      },
      {
        t: 0,
        value: 0.00897,
      },
      {
        t: 10,
        value: 0.00596,
      },
      {
        t: 20,
        value: 0.00431,
      },
      {
        t: 30,
        value: 0.00305,
      },
      {
        t: 40,
        value: 0.00232,
      },
      {
        t: 50,
        value: 0.00182,
      },
      {
        t: 60,
        value: 0.00148,
      },
      {
        t: 70,
        value: 0.00123,
      },
      {
        t: 80,
        value: 0.00104,
      },
      {
        t: 90,
        value: 0.00091,
      },
      {
        t: 100,
        value: 0.0008,
      },
      {
        t: 110,
        value: 0.00071,
      },
      {
        t: 120,
        value: 0.00065,
      },
    ],
  },
  {
    name: 'Этиленгликоль-55%',
    density: [
      {
        t: -10,
        value: 1104,
      },
      {
        t: 0,
        value: 1098.4,
      },
      {
        t: 10,
        value: 1092.8,
      },
      {
        t: 20,
        value: 1086.8,
      },
      {
        t: 30,
        value: 1080.6,
      },
      {
        t: 40,
        value: 1074.3,
      },
      {
        t: 50,
        value: 1067.8,
      },
      {
        t: 60,
        value: 1061.4,
      },
      {
        t: 70,
        value: 1054.9,
      },
      {
        t: 80,
        value: 1048.4,
      },
      {
        t: 90,
        value: 1042,
      },
      {
        t: 100,
        value: 1035.7,
      },
      {
        t: 110,
        value: 1029.5,
      },
      {
        t: 120,
        value: 1023.3,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3094.7,
      },
      {
        t: 0,
        value: 3143.2,
      },
      {
        t: 10,
        value: 3188,
      },
      {
        t: 20,
        value: 3233,
      },
      {
        t: 30,
        value: 3277.8,
      },
      {
        t: 40,
        value: 3322.2,
      },
      {
        t: 50,
        value: 3366,
      },
      {
        t: 60,
        value: 3409.1,
      },
      {
        t: 70,
        value: 3451.4,
      },
      {
        t: 80,
        value: 3492.8,
      },
      {
        t: 90,
        value: 3533.2,
      },
      {
        t: 100,
        value: 3572.7,
      },
      {
        t: 110,
        value: 3611.2,
      },
      {
        t: 120,
        value: 3648.7,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.4,
      },
      {
        t: 0,
        value: 0.395,
      },
      {
        t: 10,
        value: 0.391,
      },
      {
        t: 20,
        value: 0.387,
      },
      {
        t: 30,
        value: 0.382,
      },
      {
        t: 40,
        value: 0.378,
      },
      {
        t: 50,
        value: 0.373,
      },
      {
        t: 60,
        value: 0.368,
      },
      {
        t: 70,
        value: 0.364,
      },
      {
        t: 80,
        value: 0.359,
      },
      {
        t: 90,
        value: 0.355,
      },
      {
        t: 100,
        value: 0.351,
      },
      {
        t: 110,
        value: 0.346,
      },
      {
        t: 120,
        value: 0.342,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.018,
      },
      {
        t: 0,
        value: 0.0105,
      },
      {
        t: 10,
        value: 0.00695,
      },
      {
        t: 20,
        value: 0.00482,
      },
      {
        t: 30,
        value: 0.0035,
      },
      {
        t: 40,
        value: 0.00264,
      },
      {
        t: 50,
        value: 0.00206,
      },
      {
        t: 60,
        value: 0.00166,
      },
      {
        t: 70,
        value: 0.00137,
      },
      {
        t: 80,
        value: 0.00115,
      },
      {
        t: 90,
        value: 0.001,
      },
      {
        t: 100,
        value: 0.00087,
      },
      {
        t: 110,
        value: 0.00078,
      },
      {
        t: 120,
        value: 0.0007,
      },
    ],
  },
  {
    name: 'Этиленгликоль-60%',
    density: [
      {
        t: -10,
        value: 1113.4,
      },
      {
        t: 0,
        value: 1107.3,
      },
      {
        t: 10,
        value: 1101.3,
      },
      {
        t: 20,
        value: 1094.9,
      },
      {
        t: 30,
        value: 1088.4,
      },
      {
        t: 40,
        value: 1081.7,
      },
      {
        t: 50,
        value: 1074.9,
      },
      {
        t: 60,
        value: 1068.2,
      },
      {
        t: 70,
        value: 1061.4,
      },
      {
        t: 80,
        value: 1054.7,
      },
      {
        t: 90,
        value: 1048,
      },
      {
        t: 100,
        value: 1041.5,
      },
      {
        t: 110,
        value: 1035,
      },
      {
        t: 120,
        value: 1028.7,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 2991.8,
      },
      {
        t: 0,
        value: 3046.1,
      },
      {
        t: 10,
        value: 3095.7,
      },
      {
        t: 20,
        value: 3145.1,
      },
      {
        t: 30,
        value: 3194.1,
      },
      {
        t: 40,
        value: 3242.5,
      },
      {
        t: 50,
        value: 3290,
      },
      {
        t: 60,
        value: 3336.6,
      },
      {
        t: 70,
        value: 3382.1,
      },
      {
        t: 80,
        value: 3426.6,
      },
      {
        t: 90,
        value: 3469.9,
      },
      {
        t: 100,
        value: 3512.2,
      },
      {
        t: 110,
        value: 3553.3,
      },
      {
        t: 120,
        value: 3593.3,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.387,
      },
      {
        t: 0,
        value: 0.38,
      },
      {
        t: 10,
        value: 0.374,
      },
      {
        t: 20,
        value: 0.368,
      },
      {
        t: 30,
        value: 0.362,
      },
      {
        t: 40,
        value: 0.356,
      },
      {
        t: 50,
        value: 0.35,
      },
      {
        t: 60,
        value: 0.344,
      },
      {
        t: 70,
        value: 0.338,
      },
      {
        t: 80,
        value: 0.332,
      },
      {
        t: 90,
        value: 0.327,
      },
      {
        t: 100,
        value: 0.321,
      },
      {
        t: 110,
        value: 0.316,
      },
      {
        t: 120,
        value: 0.311,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0214,
      },
      {
        t: 0,
        value: 0.0124,
      },
      {
        t: 10,
        value: 0.0081,
      },
      {
        t: 20,
        value: 0.00557,
      },
      {
        t: 30,
        value: 0.00401,
      },
      {
        t: 40,
        value: 0.003,
      },
      {
        t: 50,
        value: 0.00233,
      },
      {
        t: 60,
        value: 0.00186,
      },
      {
        t: 70,
        value: 0.00152,
      },
      {
        t: 80,
        value: 0.00128,
      },
      {
        t: 90,
        value: 0.00109,
      },
      {
        t: 100,
        value: 0.00096,
      },
      {
        t: 110,
        value: 0.00084,
      },
      {
        t: 120,
        value: 0.00076,
      },
    ],
  },
  {
    name: 'Этиленгликоль-65%',
    density: [
      {
        t: -10,
        value: 1122.8,
      },
      {
        t: 0,
        value: 1116.2,
      },
      {
        t: 10,
        value: 1109.7,
      },
      {
        t: 20,
        value: 1103,
      },
      {
        t: 30,
        value: 1096.1,
      },
      {
        t: 40,
        value: 1089.1,
      },
      {
        t: 50,
        value: 1082,
      },
      {
        t: 60,
        value: 1075,
      },
      {
        t: 70,
        value: 1067.9,
      },
      {
        t: 80,
        value: 1060.9,
      },
      {
        t: 90,
        value: 1054,
      },
      {
        t: 100,
        value: 1047.3,
      },
      {
        t: 110,
        value: 1040.6,
      },
      {
        t: 120,
        value: 1034,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 2888.9,
      },
      {
        t: 0,
        value: 2948.9,
      },
      {
        t: 10,
        value: 3003.3,
      },
      {
        t: 20,
        value: 3057.3,
      },
      {
        t: 30,
        value: 3110.5,
      },
      {
        t: 40,
        value: 3162.8,
      },
      {
        t: 50,
        value: 3214,
      },
      {
        t: 60,
        value: 3264,
      },
      {
        t: 70,
        value: 3312.8,
      },
      {
        t: 80,
        value: 3360.3,
      },
      {
        t: 90,
        value: 3406.6,
      },
      {
        t: 100,
        value: 3451.6,
      },
      {
        t: 110,
        value: 3495.3,
      },
      {
        t: 120,
        value: 3537.8,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.374,
      },
      {
        t: 0,
        value: 0.365,
      },
      {
        t: 10,
        value: 0.357,
      },
      {
        t: 20,
        value: 0.349,
      },
      {
        t: 30,
        value: 0.342,
      },
      {
        t: 40,
        value: 0.334,
      },
      {
        t: 50,
        value: 0.326,
      },
      {
        t: 60,
        value: 0.319,
      },
      {
        t: 70,
        value: 0.312,
      },
      {
        t: 80,
        value: 0.305,
      },
      {
        t: 90,
        value: 0.298,
      },
      {
        t: 100,
        value: 0.292,
      },
      {
        t: 110,
        value: 0.285,
      },
      {
        t: 120,
        value: 0.279,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0255,
      },
      {
        t: 0,
        value: 0.0153,
      },
      {
        t: 10,
        value: 0.00984,
      },
      {
        t: 20,
        value: 0.00643,
      },
      {
        t: 30,
        value: 0.00459,
      },
      {
        t: 40,
        value: 0.00341,
      },
      {
        t: 50,
        value: 0.00263,
      },
      {
        t: 60,
        value: 0.00208,
      },
      {
        t: 70,
        value: 0.0017,
      },
      {
        t: 80,
        value: 0.00141,
      },
      {
        t: 90,
        value: 0.0012,
      },
      {
        t: 100,
        value: 0.00104,
      },
      {
        t: 110,
        value: 0.00092,
      },
      {
        t: 120,
        value: 0.00082,
      },
    ],
  },
  {
    name: 'Пропиленгликоль-30%',
    density: [
      {
        t: -10,
        value: 1034.9,
      },
      {
        t: 0,
        value: 1031.6,
      },
      {
        t: 10,
        value: 1027,
      },
      {
        t: 20,
        value: 1021.9,
      },
      {
        t: 30,
        value: 1016.2,
      },
      {
        t: 40,
        value: 1010.1,
      },
      {
        t: 50,
        value: 1003.7,
      },
      {
        t: 60,
        value: 997,
      },
      {
        t: 70,
        value: 990.1,
      },
      {
        t: 80,
        value: 983.2,
      },
      {
        t: 90,
        value: 976.1,
      },
      {
        t: 100,
        value: 969.1,
      },
      {
        t: 110,
        value: 962.1,
      },
      {
        t: 120,
        value: 955.1,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3827.8,
      },
      {
        t: 0,
        value: 3841.2,
      },
      {
        t: 10,
        value: 3853.7,
      },
      {
        t: 20,
        value: 3866.5,
      },
      {
        t: 30,
        value: 3879.4,
      },
      {
        t: 40,
        value: 3892.3,
      },
      {
        t: 50,
        value: 3905.1,
      },
      {
        t: 60,
        value: 3917.7,
      },
      {
        t: 70,
        value: 3930.2,
      },
      {
        t: 80,
        value: 3942.5,
      },
      {
        t: 90,
        value: 3954.6,
      },
      {
        t: 100,
        value: 3966.4,
      },
      {
        t: 110,
        value: 3978,
      },
      {
        t: 120,
        value: 3989.2,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.443,
      },
      {
        t: 0,
        value: 0.452,
      },
      {
        t: 10,
        value: 0.46,
      },
      {
        t: 20,
        value: 0.468,
      },
      {
        t: 30,
        value: 0.475,
      },
      {
        t: 40,
        value: 0.482,
      },
      {
        t: 50,
        value: 0.488,
      },
      {
        t: 60,
        value: 0.494,
      },
      {
        t: 70,
        value: 0.5,
      },
      {
        t: 80,
        value: 0.506,
      },
      {
        t: 90,
        value: 0.511,
      },
      {
        t: 100,
        value: 0.517,
      },
      {
        t: 110,
        value: 0.522,
      },
      {
        t: 120,
        value: 0.526,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0128,
      },
      {
        t: 0,
        value: 0.00704,
      },
      {
        t: 10,
        value: 0.00422,
      },
      {
        t: 20,
        value: 0.00296,
      },
      {
        t: 30,
        value: 0.00209,
      },
      {
        t: 40,
        value: 0.00155,
      },
      {
        t: 50,
        value: 0.0012,
      },
      {
        t: 60,
        value: 0.00096,
      },
      {
        t: 70,
        value: 0.00079,
      },
      {
        t: 80,
        value: 0.00067,
      },
      {
        t: 90,
        value: 0.00058,
      },
      {
        t: 100,
        value: 0.00051,
      },
      {
        t: 110,
        value: 0.00046,
      },
      {
        t: 120,
        value: 0.00042,
      },
    ],
  },
  {
    name: 'Пропиленгликоль-35%',
    density: [
      {
        t: -10,
        value: 1040.3,
      },
      {
        t: 0,
        value: 1036.6,
      },
      {
        t: 10,
        value: 1031.4,
      },
      {
        t: 20,
        value: 1025.8,
      },
      {
        t: 30,
        value: 1019.7,
      },
      {
        t: 40,
        value: 1013.2,
      },
      {
        t: 50,
        value: 1006.4,
      },
      {
        t: 60,
        value: 999.3,
      },
      {
        t: 70,
        value: 992.1,
      },
      {
        t: 80,
        value: 984.9,
      },
      {
        t: 90,
        value: 977.6,
      },
      {
        t: 100,
        value: 970.2,
      },
      {
        t: 110,
        value: 963,
      },
      {
        t: 120,
        value: 955.7,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3757.2,
      },
      {
        t: 0,
        value: 3841.2,
      },
      {
        t: 10,
        value: 3790.7,
      },
      {
        t: 20,
        value: 3806.6,
      },
      {
        t: 30,
        value: 3822.5,
      },
      {
        t: 40,
        value: 3838.2,
      },
      {
        t: 50,
        value: 3853.6,
      },
      {
        t: 60,
        value: 3868.7,
      },
      {
        t: 70,
        value: 3883.5,
      },
      {
        t: 80,
        value: 3898,
      },
      {
        t: 90,
        value: 3912.1,
      },
      {
        t: 100,
        value: 3925.9,
      },
      {
        t: 110,
        value: 3939.3,
      },
      {
        t: 120,
        value: 3952.3,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.428,
      },
      {
        t: 0,
        value: 0.435,
      },
      {
        t: 10,
        value: 0.441,
      },
      {
        t: 20,
        value: 0.446,
      },
      {
        t: 30,
        value: 0.452,
      },
      {
        t: 40,
        value: 0.457,
      },
      {
        t: 50,
        value: 0.462,
      },
      {
        t: 60,
        value: 0.467,
      },
      {
        t: 70,
        value: 0.471,
      },
      {
        t: 80,
        value: 0.475,
      },
      {
        t: 90,
        value: 0.48,
      },
      {
        t: 100,
        value: 0.484,
      },
      {
        t: 110,
        value: 0.487,
      },
      {
        t: 120,
        value: 0.491,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0167,
      },
      {
        t: 0,
        value: 0.00888,
      },
      {
        t: 10,
        value: 0.00543,
      },
      {
        t: 20,
        value: 0.00355,
      },
      {
        t: 30,
        value: 0.00245,
      },
      {
        t: 40,
        value: 0.00178,
      },
      {
        t: 50,
        value: 0.00135,
      },
      {
        t: 60,
        value: 0.00106,
      },
      {
        t: 70,
        value: 0.00086,
      },
      {
        t: 80,
        value: 0.00071,
      },
      {
        t: 90,
        value: 0.00061,
      },
      {
        t: 100,
        value: 0.00053,
      },
      {
        t: 110,
        value: 0.00047,
      },
      {
        t: 120,
        value: 0.00042,
      },
    ],
  },
  {
    name: 'Пропиленгликоль-40%',
    density: [
      {
        t: -10,
        value: 1045.8,
      },
      {
        t: 0,
        value: 1041.1,
      },
      {
        t: 10,
        value: 1035.8,
      },
      {
        t: 20,
        value: 1029.8,
      },
      {
        t: 30,
        value: 1023.2,
      },
      {
        t: 40,
        value: 1016.3,
      },
      {
        t: 50,
        value: 1009.1,
      },
      {
        t: 60,
        value: 1001.7,
      },
      {
        t: 70,
        value: 994.2,
      },
      {
        t: 80,
        value: 986.6,
      },
      {
        t: 90,
        value: 979,
      },
      {
        t: 100,
        value: 971.4,
      },
      {
        t: 110,
        value: 963.8,
      },
      {
        t: 120,
        value: 956.3,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3686.6,
      },
      {
        t: 0,
        value: 3708.2,
      },
      {
        t: 10,
        value: 3727.6,
      },
      {
        t: 20,
        value: 3746.6,
      },
      {
        t: 30,
        value: 3765.6,
      },
      {
        t: 40,
        value: 3784,
      },
      {
        t: 50,
        value: 3802.1,
      },
      {
        t: 60,
        value: 3819.7,
      },
      {
        t: 70,
        value: 3836.8,
      },
      {
        t: 80,
        value: 3853.5,
      },
      {
        t: 90,
        value: 3869.6,
      },
      {
        t: 100,
        value: 3885.4,
      },
      {
        t: 110,
        value: 3900.6,
      },
      {
        t: 120,
        value: 3915.4,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.413,
      },
      {
        t: 0,
        value: 0.417,
      },
      {
        t: 10,
        value: 0.421,
      },
      {
        t: 20,
        value: 0.425,
      },
      {
        t: 30,
        value: 0.428,
      },
      {
        t: 40,
        value: 0.432,
      },
      {
        t: 50,
        value: 0.435,
      },
      {
        t: 60,
        value: 0.439,
      },
      {
        t: 70,
        value: 0.442,
      },
      {
        t: 80,
        value: 0.445,
      },
      {
        t: 90,
        value: 0.448,
      },
      {
        t: 100,
        value: 0.451,
      },
      {
        t: 110,
        value: 0.453,
      },
      {
        t: 120,
        value: 0.456,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0217,
      },
      {
        t: 0,
        value: 0.0111,
      },
      {
        t: 10,
        value: 0.00667,
      },
      {
        t: 20,
        value: 0.00425,
      },
      {
        t: 30,
        value: 0.00287,
      },
      {
        t: 40,
        value: 0.00204,
      },
      {
        t: 50,
        value: 0.00151,
      },
      {
        t: 60,
        value: 0.00117,
      },
      {
        t: 70,
        value: 0.00093,
      },
      {
        t: 80,
        value: 0.00077,
      },
      {
        t: 90,
        value: 0.00064,
      },
      {
        t: 100,
        value: 0.00055,
      },
      {
        t: 110,
        value: 0.00048,
      },
      {
        t: 120,
        value: 0.00043,
      },
    ],
  },
  {
    name: 'Пропиленгликоль-45%',
    density: [
      {
        t: -10,
        value: 1051.3,
      },
      {
        t: 0,
        value: 1045.9,
      },
      {
        t: 10,
        value: 1040.2,
      },
      {
        t: 20,
        value: 1033.7,
      },
      {
        t: 30,
        value: 1026.7,
      },
      {
        t: 40,
        value: 1019.4,
      },
      {
        t: 50,
        value: 1011.8,
      },
      {
        t: 60,
        value: 1004.1,
      },
      {
        t: 70,
        value: 996.2,
      },
      {
        t: 80,
        value: 988.3,
      },
      {
        t: 90,
        value: 980.4,
      },
      {
        t: 100,
        value: 972.5,
      },
      {
        t: 110,
        value: 964.7,
      },
      {
        t: 120,
        value: 956.9,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3616.1,
      },
      {
        t: 0,
        value: 3641.7,
      },
      {
        t: 10,
        value: 3664.5,
      },
      {
        t: 20,
        value: 3686.9,
      },
      {
        t: 30,
        value: 3708.7,
      },
      {
        t: 40,
        value: 3729.9,
      },
      {
        t: 50,
        value: 3750.6,
      },
      {
        t: 60,
        value: 3770.6,
      },
      {
        t: 70,
        value: 3790.1,
      },
      {
        t: 80,
        value: 3808.9,
      },
      {
        t: 90,
        value: 3827.2,
      },
      {
        t: 100,
        value: 3844.8,
      },
      {
        t: 110,
        value: 3862,
      },
      {
        t: 120,
        value: 3878.5,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.397,
      },
      {
        t: 0,
        value: 0.399,
      },
      {
        t: 10,
        value: 0.401,
      },
      {
        t: 20,
        value: 0.403,
      },
      {
        t: 30,
        value: 0.405,
      },
      {
        t: 40,
        value: 0.407,
      },
      {
        t: 50,
        value: 0.409,
      },
      {
        t: 60,
        value: 0.411,
      },
      {
        t: 70,
        value: 0.412,
      },
      {
        t: 80,
        value: 0.414,
      },
      {
        t: 90,
        value: 0.416,
      },
      {
        t: 100,
        value: 0.418,
      },
      {
        t: 110,
        value: 0.419,
      },
      {
        t: 120,
        value: 0.421,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0282,
      },
      {
        t: 0,
        value: 0.0141,
      },
      {
        t: 10,
        value: 0.00819,
      },
      {
        t: 20,
        value: 0.0051,
      },
      {
        t: 30,
        value: 0.00337,
      },
      {
        t: 40,
        value: 0.00234,
      },
      {
        t: 50,
        value: 0.0017,
      },
      {
        t: 60,
        value: 0.00129,
      },
      {
        t: 70,
        value: 0.00101,
      },
      {
        t: 80,
        value: 0.00081,
      },
      {
        t: 90,
        value: 0.00067,
      },
      {
        t: 100,
        value: 0.00057,
      },
      {
        t: 110,
        value: 0.00049,
      },
      {
        t: 120,
        value: 0.00043,
      },
    ],
  },
  {
    name: 'Пропиленгликоль-50%',
    density: [
      {
        t: -10,
        value: 1056.8,
      },
      {
        t: 0,
        value: 1050.8,
      },
      {
        t: 10,
        value: 1044.6,
      },
      {
        t: 20,
        value: 1037.6,
      },
      {
        t: 30,
        value: 1030.2,
      },
      {
        t: 40,
        value: 1022.5,
      },
      {
        t: 50,
        value: 1014.6,
      },
      {
        t: 60,
        value: 1006.4,
      },
      {
        t: 70,
        value: 998.3,
      },
      {
        t: 80,
        value: 990,
      },
      {
        t: 90,
        value: 981.8,
      },
      {
        t: 100,
        value: 973.7,
      },
      {
        t: 110,
        value: 965.6,
      },
      {
        t: 120,
        value: 957.6,
      },
    ],
    specific_heat: [
      {
        t: -10,
        value: 3545.5,
      },
      {
        t: 0,
        value: 3675.2,
      },
      {
        t: 10,
        value: 3601.4,
      },
      {
        t: 20,
        value: 3627,
      },
      {
        t: 30,
        value: 3651.8,
      },
      {
        t: 40,
        value: 3675.8,
      },
      {
        t: 50,
        value: 3699.1,
      },
      {
        t: 60,
        value: 3721.6,
      },
      {
        t: 70,
        value: 3743.3,
      },
      {
        t: 80,
        value: 3764.4,
      },
      {
        t: 90,
        value: 3784.7,
      },
      {
        t: 100,
        value: 3804.3,
      },
      {
        t: 110,
        value: 3823.3,
      },
      {
        t: 120,
        value: 3841.6,
      },
    ],
    thermal_conductivity: [
      {
        t: -10,
        value: 0.382,
      },
      {
        t: 0,
        value: 0.382,
      },
      {
        t: 10,
        value: 0.381,
      },
      {
        t: 20,
        value: 0.381,
      },
      {
        t: 30,
        value: 0.382,
      },
      {
        t: 40,
        value: 0.382,
      },
      {
        t: 50,
        value: 0.382,
      },
      {
        t: 60,
        value: 0.383,
      },
      {
        t: 70,
        value: 0.383,
      },
      {
        t: 80,
        value: 0.384,
      },
      {
        t: 90,
        value: 0.384,
      },
      {
        t: 100,
        value: 0.385,
      },
      {
        t: 110,
        value: 0.385,
      },
      {
        t: 120,
        value: 0.386,
      },
    ],
    viscosity: [
      {
        t: -10,
        value: 0.0366,
      },
      {
        t: 0,
        value: 0.0177,
      },
      {
        t: 10,
        value: 0.01,
      },
      {
        t: 20,
        value: 0.00611,
      },
      {
        t: 30,
        value: 0.00395,
      },
      {
        t: 40,
        value: 0.00269,
      },
      {
        t: 50,
        value: 0.00192,
      },
      {
        t: 60,
        value: 0.00142,
      },
      {
        t: 70,
        value: 0.00109,
      },
      {
        t: 80,
        value: 0.00087,
      },
      {
        t: 90,
        value: 0.00071,
      },
      {
        t: 100,
        value: 0.00059,
      },
      {
        t: 110,
        value: 0.0005,
      },
      {
        t: 120,
        value: 0.00043,
      },
    ],
  },
  // next condition, add in the end of array
];
//module.exports = data;
export default data;

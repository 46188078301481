import React, { useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Toolbar,
  CardMedia,
  AppBar,
  Paper,
  Tab,
  Tabs,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BarChartIcon from '@material-ui/icons/BarChart';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PrintIcon from '@material-ui/icons/Print';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useTheme } from '@material-ui/core/styles';

import MainClass from '../../../main/MainClass';

import ShoppingCartComponent from '../ShoppingCartComponent/ShoppingCartComponent';

import commonStyles from '../../../commonUtils/commonStyles';
import { ROUTES_LIST, API_ENDPOINTS_LIST } from '../../../constants';
import { GlobalContext } from '../../..';
import useServerRequest from '../../../hooks/serverRequest';

const drawerWidth = 340;
let theme;

function HeaderComponent(props) {
  theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [topTabValue, setTopTabValue] = React.useState(props.topTabValue === undefined ? 0 : props.topTabValue);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userRole, setUserRole] = React.useState('');

  const openUserMenu = Boolean(anchorEl);
  const { setAuthTokens, setConsultant, setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const history = useHistory();

  const { getRequest: getConsultantInfo } = useServerRequest(setGlobalLoading);

  const { getRequest: getGlobalCartData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Header user role: ' + MainClass.userRole);
    setUserRole(MainClass.userRole);
    if (MainClass.userRole !== 'admin' && MainClass.userRole !== 'calculator') {
      getConsultantInfo(API_ENDPOINTS_LIST.get_consultant, '', 'GET').then((res) => {
        if (res && res.status === 200) {
          setConsultant(res.result);
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Ошибка при получении данных консультанта`,
            toastStyle: 'error',
          }));
        }
      });
    }
    if (MainClass.userRole !== 'admin' && MainClass.userRole !== 'calculator') {
      getGlobalCartData(API_ENDPOINTS_LIST.get_global_cart_data, '', 'GET').then((res) => {
        if (res && res.status === 200) {
          //console.log('getGlobalCartData Header: ' + JSON.stringify(res));
          MainClass.shoppingCartGlobalData.cartItemsNum = Number(res.result.itemsCount);
          MainClass.shoppingCartGlobalData.userDiscount = Number(res.result.discount);
          MainClass.shoppingCartGlobalData.terminals = res.result.terminals;
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Ошибка при получении данных корзины`,
            toastStyle: 'error',
          }));
        }
      });
    }
  }, [MainClass.userRole]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    setAuthTokens('');
  };

  const handleBackButton = () => {
    history.goBack();
  };

  const TopTabBar = () => {
    if (props.topTabBarEnabled) {
      return (
        <Tabs
          value={topTabValue}
          onChange={(event, newValue) => {
            //console.log('New tab pressed num: ' + newValue);
            setTopTabValue(newValue);
            if (newValue === 0) history.push(ROUTES_LIST.rptoCalculation);
            if (newValue === 1) history.push(ROUTES_LIST.technologyCalculation);
            //if (newValue === 2) history.push(ROUTES_LIST.hotWaterSupply);
            if (newValue === 2) history.push(ROUTES_LIST.hotWaterSupplyCalc);
            if (newValue === 3) history.push(ROUTES_LIST.rptoCalculationPoverochniy);
          }}
          textColor="inherit"
          TabIndicatorProps={{ style: { ...getStyles().tabIndicator } }}
          centered
        >
          <Tab
            style={{ minWidth: '200px' }}
            label={
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular16,
                  ...{
                    textTransform: 'none',
                    color: topTabValue === 0 ? commonStyles.color.blue : commonStyles.color.tabInactive,
                  },
                }}
              >
                Расчет ОВИК
              </Typography>
            }
          />
          <Tab
            style={{ minWidth: '200px' }}
            label={
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular16,
                  ...{
                    textTransform: 'none',
                    color: topTabValue === 1 ? commonStyles.color.blue : commonStyles.color.tabInactive,
                  },
                }}
              >
                Расчет "Технология"
              </Typography>
            }
          />
          {/*<Tab
            style={{ minWidth: '325px' }}
            label={
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular16,
                  ...{
                    textTransform: 'none',
                    color: topTabValue === 2 ? commonStyles.color.blue : commonStyles.color.tabInactive,
                  },
                }}
              >
                Двухступенчатая смешанная схема ГВС
              </Typography>
            }
          />*/}
          <Tab
            style={{ minWidth: '200px' }}
            label={
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular16,
                  ...{
                    textTransform: 'none',
                    color: topTabValue === 2 ? commonStyles.color.blue : commonStyles.color.tabInactive,
                  },
                }}
              >
                Расчет ДГВС
              </Typography>
            }
          />
          {userRole === 'calculator' && (
            <Tab
              style={{ minWidth: '200px' }}
              label={
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular16,
                    ...{
                      textTransform: 'none',
                      color: topTabValue === 3 ? commonStyles.color.blue : commonStyles.color.tabInactive,
                    },
                  }}
                >
                  Поверочный расчет
                </Typography>
              }
            />
          )}
        </Tabs>
      );
    } else {
      return null;
    }
  };

  const LeftHeaderButton = () => {
    switch (props.leftButtonType) {
      case 'ArrowBack':
        return (
          <IconButton color="inherit" onClick={handleBackButton} edge="start" style={getStyles().menuButton}>
            <ArrowBackIcon />
          </IconButton>
        );
      default:
        return (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            //className={clsx(getStyles().menuButton, open && getStyles().hide)}
            style={open === false ? getStyles().menuButton : getStyles().hide}
          >
            <MenuIcon />
          </IconButton>
        );
    }
  };

  const menuList = {
    line1: [
      {
        title: 'Расчет РПТО',
        icon: <BarChartIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.rptoCalculation,
        role: ['user', 'calculator'],
      },
      {
        title: 'Расчет блочного ИТП',
        icon: <TrackChangesIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.itpCalculation,
        role: ['user', 'calculator'],
      },
      {
        title: 'Панель администрирования',
        icon: <SupervisorAccountIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.adminPanel,
        role: ['admin'],
      },
    ],
    line2: [
      {
        title: 'Каталоги и буклеты НПО ЭТРА',
        icon: <MenuBookIcon style={getStyles().listIcon} />,
        role: ['user', 'calculator'],
        link: ROUTES_LIST.catalogs,
      },
      {
        title: 'Разрешительные документы',
        icon: <LibraryBooksIcon style={getStyles().listIcon} />,
        role: ['user', 'calculator'],
        link: ROUTES_LIST.documents,
      },
      {
        title: 'Каталог 3D моделей',
        icon: <CropFreeIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.modelsCatalog,
        role: ['user', 'calculator'],
      },
      {
        title: 'Архив расчетов',
        icon: <PrintIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.calculationArchive,
        role: ['user'],
      },
      {
        title: 'Коммерческие предложения',
        icon: <AttachMoneyIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.commercialOfferHistory,
        role: ['user'],
      },
      {
        title: 'Счета, оплаты, отгрузки',
        icon: <ReceiptIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.invoicesPaymentsHistory,
        role: ['user'],
      },
    ],
  };

  const shoppingCartElement = () => {
    //console.log('shoppingCartElement consultant: ' + consultant + ' userRole: ' + userRole);
    if (userRole === 'user' && props.hideCart !== 'hide') {
      return <ShoppingCartComponent color="inherit" onClick={() => history.push(ROUTES_LIST.commercialOffer)} />;
    } else {
      return null;
    }
  };

  const menuListContent = Object.keys(menuList).map((key, i) => {
    const isLast = Object.keys(menuList).length === i + 1;

    return (
      <List key={i} component="nav">
        {menuList[key].map(({ title, icon, link, role }, index) => {
          if (role.includes(userRole)) {
            if (link) {
              return (
                <Link to={link} key={`${key}-${index}`} style={getStyles().listLink}>
                  <ListItem button>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} style={commonStyles.text.robotoRegular18} />
                  </ListItem>
                </Link>
              );
            }
            return (
              <ListItem button key={`${key}-${index}`}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} style={commonStyles.text.robotoRegular18} />
              </ListItem>
            );
          }
        })}
        {!isLast && <Divider />}
      </List>
    );
  });

  return (
    <AppBar
      position="fixed"
      style={{
        ...getStyles().topAppBar,
        ...{ height: props.topTabBarEnabled ? '198px' : '150px' },
      }}
    >
      <CardMedia component="img" style={getStyles().logoImage} image={require('../../../assets/Logo.png')} />
      <AppBar elevation={5} position="static" style={open === false ? getStyles().appBar : getStyles().appBarShift}>
        <Toolbar style={getStyles().topToolbar}>
          <LeftHeaderButton />
          <Typography noWrap style={commonStyles.text.robotoRegular24}>
            {MainClass.getScreenNames()[props.screenNumber]}
          </Typography>
          <Box style={getStyles().rightSideButtonsContainer}>
            {shoppingCartElement()}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => {
                //console.log(event.currentTarget);
                setAnchorEl(event.currentTarget);
              }}
              color="inherit"
              //style={{ marginRight: 'auto' }}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openUserMenu}
              onClose={() => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                style: { width: '250px' },
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <PersonOutlineIcon
                  style={{
                    color: commonStyles.color.blue,
                    fontSize: '25px',
                    marginRight: '15px',
                  }}
                />
                <Typography
                  style={commonStyles.text.robotoRegular16}
                  onClick={() => history.push(ROUTES_LIST.personalInfo)}
                >
                  Личные данные
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  logOut();
                }}
              >
                <ExitToAppIcon
                  style={{
                    color: commonStyles.color.blue,
                    fontSize: '25px',
                    marginRight: '15px',
                  }}
                />
                <Typography style={commonStyles.text.robotoRegular16}>Выход из системы</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Paper square style={getStyles().headerMenuPaper}>
        <TopTabBar />
      </Paper>
      <Drawer variant="persistent" anchor="left" open={open} PaperProps={{ style: { ...getStyles().drawerPaper } }}>
        <div style={getStyles().drawerHeader}>
          <Divider />
          <IconButton onClick={handleDrawerClose}>
            {open === true ? (
              <ChevronLeftIcon
                style={{
                  color: commonStyles.color.blue,
                  fontSize: '35px',
                }}
              />
            ) : (
              <ChevronRightIcon style={{ color: commonStyles.color.blue, fontSize: '35px' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        {menuListContent}
      </Drawer>
    </AppBar>
  );
}

const getStyles = () => {
  const styles = {
    topAppBar: {
      top: '0',
      bottom: 'auto',
      height: '198px',
      background: commonStyles.color.white,
    },
    logoImage: {
      marginTop: '20px',
      marginLeft: '20px',
      width: '252px',
      objectFit: 'initial',
    },
    topToolbar: {
      background: commonStyles.color.blue,
      height: '65px',
    },
    headerMenuPaper: {
      background: commonStyles.color.white,
      height: '58px',
    },
    appBar: {
      height: '65px',
      //background: commonStyles.color.blue,
      marginTop: '20px',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      height: '65px',
      //background: commonStyles.color.blue,
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop: '20px',
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawerPaper: {
      width: `${drawerWidth}px`,
      // height: '430px',
      marginTop: '84px',
      border: '0px solid',
      borderTop: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 5.5px 5px rgba(0,0,0,0.24), 0px 9px 18px rgba(0,0,0,0.18)',
    },
    drawerHeader: {
      display: 'flex',
      height: '65px',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      //...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    tabIndicator: {
      backgroundColor: commonStyles.color.blue,
      textColor: commonStyles.color.blue,
    },
    snackBar: {
      bottom: '50px',
    },
    listIcon: {
      color: commonStyles.color.blue,
      fontSize: '25px',
    },
    listLink: {
      textDecoration: 'none',
      color: 'inherit',
    },
    rightSideButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '65px',
      marginLeft: 'auto',
      alignItems: 'center',
      //border: '1px solid',
      //borderColor: 'red',
    },
  };
  return styles;
};

export default HeaderComponent;

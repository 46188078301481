import values from './mainValues.js'
export default class Value {
  static convertValues = values;
  constructor(value, dimension) {
    ////////////////////////////
    //this.value; // ЗНАЧЕНИЕ
    //this.dimension; // РАЗМЕРНОСТЬ
    ///////////////////////////
    if (!dimension) {
      if (typeof value === 'string') {
        dimension = value;
        value = undefined;
      }
      if (typeof value === 'object') {
        dimension = value.dimension;
        value = +value.value;
      }
    }
    // CHECK DIMENSION
    if (!Object.keys(Value.convertValues).some((el) => el === dimension)) throw 'we have no this dimention';
    ///////////////////////////////
    this.value = value;
    this.dimension = dimension;
  }
  toCI() {
    //  КОНВЕРТИРОВАТЬ В СИ
    return this.value * Value.convertValues[this.dimension];
  }
  convertFromCI(dimension) {
    if (Object.keys(Value.convertValues).some((el) => el === dimension)) this.dimension = dimension;
    else throw 'we have no this dimention';
    this.value = this.value / Value.convertValues[this.dimension];
    return this;
  }
  convertTo(dimension) {
    this.value = this.toCI();
    this.convertFromCI(dimension);
    return this;
  }
  round(nd = 3) {
    this.value = Number(this.value.toFixed(nd));
    return this;
  }
}
//module.exports = Value;
//export default Value;

import Value from '../Value';
import { linear as interpolation } from 'everpolate';
import { default as data_conditions } from '../configs/conditions/conditions'
import TemperatureValidation from '../balance_calculation/temperatureValidation';

const DEEP_COPY = (o) => JSON.parse(JSON.stringify(o))
const [NTU_1STAGE_LIMIT, NTU_2STAGE_LIMIT] = [3.5, 4.5]

export default class DGVS {
    constructor() {
        this.conditions = data_conditions.reduce((acc, cond) => {
            acc[cond.name] = cond;
            return acc;
        }, {})
    }

    _interpolate_prop_of_condition(heatBalanceData, side, prop, point) {
        const [result] = interpolation(
            point,
            heatBalanceData[side].condition[prop].map((el) => el.t),
            heatBalanceData[side].condition[prop].map((el) => el.value)
        )
        return result
    }

    _prepareHeatBalanceData(input_data) {
        const heatBalanceData = DEEP_COPY(input_data)
        heatBalanceData.N = new Value(heatBalanceData.N)
        for (let side of ['hotSide', 'coldSide']) {
            heatBalanceData[side].condition = this.conditions[heatBalanceData[side].conditionName]
            for (let key in heatBalanceData[side].props) {
                heatBalanceData[side].props[key] = new Value(heatBalanceData[side].props[key])
            }
        }
        if (heatBalanceData.heatingData.NT) {
            const NT = heatBalanceData.heatingData.NT;
            for (const key in NT) {
                NT[key] = new Value(NT[key])
            }
        }
        if (heatBalanceData.heatingData.Q_m) {
            heatBalanceData.heatingData.Q_m = new Value(heatBalanceData.heatingData.Q_m)
        }
        return heatBalanceData;
    }

    _heatingBalanceDataPatch(heatBalanceDataAllStages) {
        const { heatingData: heatBalanceData } = heatBalanceDataAllStages
        if (!heatBalanceData.Q_m) {
            for (const key in heatBalanceData.NT) heatBalanceData.NT[key] = new Value(heatBalanceData.NT[key])

            const side = 'hotSide'
            const C = this._interpolate_prop_of_condition(
                heatBalanceDataAllStages,
                side,
                'specific_heat',
                (heatBalanceData.NT.T_input.toCI() + heatBalanceData.NT.T_output.toCI()) / 2
            )
            const Q = heatBalanceData.NT.N.toCI() / C / (heatBalanceData.NT.T_input.toCI() - heatBalanceData.NT.T_output.toCI())
            heatBalanceData.Q_m = { value: Q, dimension: 'кг/с' }
        }
        heatBalanceData.Q_m = new Value(heatBalanceData.Q_m).convertTo(heatBalanceDataAllStages.coldSide.props.Q_m.dimension)
    }

    _pre1Stage(heatBalanceDataAllStages) {
        const tmpData = { T: heatBalanceDataAllStages.hotSide.props.T_output, Q: heatBalanceDataAllStages.heatingData.Q_m }
        const preHeatBalanceData1Stage = {
            coldSide: { props: {}, conditionName: heatBalanceDataAllStages.coldSide.conditionName },
            hotSide: { props: {}, conditionName: heatBalanceDataAllStages.hotSide.conditionName },
        }

        // N
        preHeatBalanceData1Stage.N = new Value(
            (heatBalanceDataAllStages.N.toCI() * heatBalanceDataAllStages.workloadRatioN1Stage) / 100,
            'вт'
        )

        // cold side
        preHeatBalanceData1Stage.coldSide.props.T_input = heatBalanceDataAllStages.coldSide.props.T_input
        preHeatBalanceData1Stage.coldSide.props.Q_m = heatBalanceDataAllStages.coldSide.props.Q_m
        preHeatBalanceData1Stage.coldSide.props.T_output = new Value(
            preHeatBalanceData1Stage.coldSide.props.T_input.toCI() +
            preHeatBalanceData1Stage.N.convertTo('Мкал/ч').value / preHeatBalanceData1Stage.coldSide.props.Q_m.value,
            'град. Цельсия'
        )

        // hotSide
        preHeatBalanceData1Stage.hotSide.props.Q_m = new Value(
            tmpData.Q.toCI() + heatBalanceDataAllStages.hotSide.props.Q_m2Stage.toCI(),
            'кг/с'
        ).convertTo(heatBalanceDataAllStages.coldSide.props.Q_m.dimension)

        preHeatBalanceData1Stage.N.convertTo('кВт')
        return preHeatBalanceData1Stage
    }

    _prepare2Stage(heatBalanceDataAllStages, preHeatBalanceData1Stage) {
        const tmpData = { T: new Value(55, 'град. Цельсия'), Q: new Value(0, 'т/ч') }
        const heatBalanceData2Stage = {
            coldSide: { props: {}, conditionName: heatBalanceDataAllStages.coldSide.conditionName },
            hotSide: { props: {}, conditionName: heatBalanceDataAllStages.hotSide.conditionName },
        }

        // cold side
        heatBalanceData2Stage.coldSide.props.Q_m = new Value(
            preHeatBalanceData1Stage.coldSide.props.Q_m.toCI() + tmpData.Q.toCI(),
            'кг/с'
        ).convertTo(heatBalanceDataAllStages.coldSide.props.Q_m.dimension)
        heatBalanceData2Stage.coldSide.props.T_output = heatBalanceDataAllStages.coldSide.props.T_output
        heatBalanceData2Stage.coldSide.props.T_input = new Value(
            (preHeatBalanceData1Stage.coldSide.props.Q_m.toCI() * preHeatBalanceData1Stage.coldSide.props.T_output.toCI() +
                tmpData.Q.toCI() * tmpData.T.toCI()) /
            (preHeatBalanceData1Stage.coldSide.props.Q_m.toCI() + tmpData.Q.toCI()),
            'град. Цельсия'
        )

        // N
        heatBalanceData2Stage.N = new Value(
            (heatBalanceDataAllStages.N.toCI() * (1 - heatBalanceDataAllStages.workloadRatioN1Stage / 100)),
            'вт'
        ).convertTo('кВт')

        // hotSide side
        heatBalanceData2Stage.hotSide.props.Q_m = heatBalanceDataAllStages.hotSide.props.Q_m2Stage
        heatBalanceData2Stage.hotSide.props.T_input = heatBalanceDataAllStages.hotSide.props.T_input
        heatBalanceData2Stage.hotSide.props.T_output = new Value(
            heatBalanceData2Stage.hotSide.props.T_input.toCI() -
            heatBalanceData2Stage.N.convertTo('Мкал/ч').value / heatBalanceData2Stage.hotSide.props.Q_m.convertTo('т/ч').value,
            'град. Цельсия'
        )

        heatBalanceData2Stage.coldSide.props.Q_m.convertTo(heatBalanceDataAllStages.coldSide.props.Q_m.dimension)
        heatBalanceData2Stage.hotSide.props.Q_m.convertTo(heatBalanceDataAllStages.coldSide.props.Q_m.dimension)
        heatBalanceData2Stage.N.convertTo('кВт')
        return heatBalanceData2Stage
    }

    _prepare1Stage(heatBalanceDataAllStages, preHeatBalanceData1Stage, heatBalanceData2Stage) {
        const heatBalanceData1Stage = preHeatBalanceData1Stage
        const tmpData = { T: heatBalanceDataAllStages.hotSide.props.T_output, Q: heatBalanceDataAllStages.heatingData.Q_m }

        // hotSIde
        heatBalanceData1Stage.hotSide.props.T_input = new Value(
            (heatBalanceData2Stage.hotSide.props.Q_m.toCI() * heatBalanceData2Stage.hotSide.props.T_output.toCI() +
                tmpData.Q.toCI() * tmpData.T.toCI()) /
            (heatBalanceData2Stage.hotSide.props.Q_m.toCI() + tmpData.Q.toCI()),
            'град. Цельсия'
        )

        heatBalanceData1Stage.hotSide.props.T_output = new Value(
            heatBalanceData1Stage.hotSide.props.T_input.toCI() -
            heatBalanceData1Stage.N.convertTo('Мкал/ч').value / heatBalanceData1Stage.hotSide.props.Q_m.convertTo('т/ч').value,
            'град. Цельсия'
        )

        heatBalanceData1Stage.hotSide.props.Q_m.convertTo(heatBalanceDataAllStages.coldSide.props.Q_m.dimension)
        heatBalanceData1Stage.N.convertTo('кВт')

        return heatBalanceData1Stage
    }

    _calculate_log_diff(THotInput, THotOutput, TColdInput, TColdOutput) {
        if ((THotInput - TColdOutput) / (THotOutput - TColdInput) == 1) THotInput += 1e-8
        return (
            (THotInput - TColdOutput - THotOutput + TColdInput) / Math.log((THotInput - TColdOutput) / (THotOutput - TColdInput))
        )
    }

    _get_NTU(heatBalanceData) {
        const logDiff = this._calculate_log_diff(heatBalanceData.hotSide.props.T_input.toCI(),
            heatBalanceData.hotSide.props.T_output.toCI(),
            heatBalanceData.coldSide.props.T_input.toCI(),
            heatBalanceData.coldSide.props.T_output.toCI())

        return (heatBalanceData.coldSide.props.T_output.toCI() - heatBalanceData.coldSide.props.T_input.toCI()) / logDiff
    }

    calculateStagesData(dataAllStages) {
        const heatBalanceDataAllStages = this._prepareHeatBalanceData(dataAllStages)
        this._heatingBalanceDataPatch(heatBalanceDataAllStages)

        // set to state
        this.heatBalanceDataAllStages = heatBalanceDataAllStages

        const preHeatBalanceData1Stage = this._pre1Stage(heatBalanceDataAllStages)
        const heatBalanceData2Stage = this._prepare2Stage(heatBalanceDataAllStages, preHeatBalanceData1Stage)
        const heatBalanceData1Stage = this._prepare1Stage(heatBalanceDataAllStages, preHeatBalanceData1Stage, heatBalanceData2Stage)

        // console.log("heatBalanceData2Stage", heatBalanceData2Stage)
        // console.log("heatBalanceData1Stage", heatBalanceData1Stage)

        // set to state
        this.heatBalanceData1Stage = heatBalanceData1Stage
        this.heatBalanceData2Stage = heatBalanceData2Stage
    }

    check() {
        // get from state
        const [heatBalanceData1Stage, heatBalanceData2Stage] =
            [this.heatBalanceData1Stage, this.heatBalanceData2Stage]

        let stageNumber = 0;
        for (let balanceData of [heatBalanceData1Stage, heatBalanceData2Stage]) {
            stageNumber++;
            console.log(balanceData);
            const balance = new TemperatureValidation(
            { ...balanceData.coldSide, ...balanceData.coldSide.props },
            { ...balanceData.hotSide, ...balanceData.hotSide.props }
            );
            const result = balance.checkBalance();
            if (!result.success) {
            return { success: false, error: { message: `${stageNumber} ступень: ${result.error.message}`, code: 0 } };
            }
        }

        const [ntu1Stage, ntu2Stage] = [this._get_NTU(heatBalanceData1Stage), this._get_NTU(heatBalanceData2Stage)]

        if (ntu1Stage > NTU_1STAGE_LIMIT) {
            return {
                success: false,
                error: { message: 'Ограничения на 1 ступени. Увеличить расход или уменьшить процент', code: 2 },
            };
        }

        if (ntu2Stage > NTU_2STAGE_LIMIT) {
            return {
                success: false,
                error: { message: 'Ограничения на 2 ступени. Увеличить расход или уменьшить процент', code: 3 },
            };
        }

        return { success: true };
    }

    _roundAllValues(obj) {
        for (let key in obj) {
            if (typeof obj[key] != 'object') {
                continue
            }
            // this is object
            if (obj[key] instanceof Value) {
                obj[key] = obj[key].round(1)
            }
            this._roundAllValues(obj[key])
        }
    }
    getDataAboutStages() {
        const { heatBalanceData1Stage, heatBalanceData2Stage } = this.getDataToServer()
        return { heatBalanceData1Stage, heatBalanceData2Stage }
    }

    getDataToServer() {
        const [heatBalanceDataAllStages, heatBalanceData1Stage, heatBalanceData2Stage] =
            [this.heatBalanceDataAllStages, this.heatBalanceData1Stage, this.heatBalanceData2Stage]

        delete heatBalanceDataAllStages.coldSide.condition;
        delete heatBalanceDataAllStages.hotSide.condition;
        [heatBalanceDataAllStages, heatBalanceData1Stage, heatBalanceData2Stage].map(this._roundAllValues.bind(this))
        return { heatBalanceData: { heatBalanceDataAllStages, heatBalanceData1Stage, heatBalanceData2Stage } }
    }

}
